// src/features/auth/components/LoginForm.tsx
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/Elements";
import { useAuth } from "@/lib/auth";
import { GoogleLogin } from "@react-oauth/google";
import { decodeJwt } from "jose";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Link,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";

const schema = z.object({
  username: z.string().min(1, "Email is required."),
  password: z.string().min(1, "Password is required."),
});

export const LoginForm = () => {
  const { login, isLoggingIn } = useAuth();
  const { addNotification } = useNotificationStore();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const [loginButtonWidth, setLoginButtonWidth] = useState<number | undefined>(
    undefined
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (loginButtonRef.current) {
      setLoginButtonWidth(loginButtonRef.current.offsetWidth);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      await login(values);
    } catch (error) {}
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const decodedToken = decodeJwt(credentialResponse.credential);
      const username = decodedToken.email;

      await login({
        username: username,
        password: credentialResponse.credential,
        googleLogin: "true",
      });
    } catch (error) {
      Sentry.captureException(error);
      addNotification({
        type: "error",
        title: "Google Login Failed",
        message: error.message,
      });
    }
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="username"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    className="shadow-sm ring-zinc-900/10 focus:ring-zinc-900/30 focus:border-zinc-900/30"
                    placeholder="Enter your email"
                    {...field}
                  />
                </FormControl>
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="shadow-sm ring-zinc-900/10 focus:ring-zinc-900/30 focus:border-zinc-900/30"
                    placeholder="Enter your password"
                    {...field}
                  />
                </FormControl>
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <Button
            isLoading={isLoggingIn}
            ref={loginButtonRef}
            type="submit"
            className="w-full"
            variant="primaryBlur"
          >
            Sign in
          </Button>
        </form>
      </Form>

      <div className="flex items-center justify-center mt-6">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="text-sm text-gray-500 dark:text-zinc-400 px-4">
          or
        </span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="mt-4 w-full">
        <GoogleLogin
          containerProps={{
            className:
              "w-full rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm",
          }}
          size="medium"
          theme="outline"
          width={loginButtonWidth}
          logo_alignment="center"
          onSuccess={handleGoogleSuccess}
          onError={() => {
            addNotification({
              type: "error",
              title: "Error",
              message: "There was an error logging in with Google.",
            });
          }}
        />
      </div>
      <div className="mt-8 text-center">
        <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
          Don't have an account?{" "}
          <Link
            to="/register"
            className="text-emerald-600 hover:text-emerald-700 font-medium"
          >
            Sign up
          </Link>
        </span>
      </div>
      <div className="mt-2 text-center">
        <Button
          variant="node"
          textClassName="text-emerald-600 hover:text-emerald-700 font-medium text-sm"
          onClick={() => {
            navigate("/resetpassword");
          }}
          size="xs"
        >
          Reset password
        </Button>
      </div>
    </div>
  );
};
