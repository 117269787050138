import React from "react";
import { TbCopy, TbEdit, TbShare, TbWorld, TbX } from "react-icons/tb";
import { useParams } from "react-router-dom";
import {
  Button,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { Switch } from "../../../components/Elements/Switch/Switch";
import { useNotificationStore } from "../../../stores/notifications";
import { useDocumentPreviewHash } from "../../documents/api/getDocumentPreviewHash";

type ShareMenuProps = {
  type: "document" | "template";
  previewHash?: string;
};

export const ShareMenu = ({
  type,
  previewHash: initialPreviewHash,
}: ShareMenuProps) => {
  const { addNotification } = useNotificationStore();
  const [open, setOpen] = React.useState(false);
  const [generatePreview, setGeneratePreview] = React.useState(false);
  const params = useParams<{ documentHash: string }>();
  const documentHash = params.documentHash;

  const { data: previewHash, refetch: generatePreviewHash } =
    useDocumentPreviewHash({
      docHash: documentHash,
      config: {
        enabled: false,
        onSuccess: (data) => {
          addNotification({
            type: "success",
            title: "Public only link generated",
          });
        },
        onError: () => {
          addNotification({
            type: "error",
            title: "Failed to generate public only link",
          });
        },
      },
    });

  const handleCopyLink = (link: string, linkType: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        addNotification({
          type: "success",
          title: `${
            linkType[0].toUpperCase() + linkType.slice(1)
          } link copied to clipboard`,
        });
        setOpen(false);
      })
      .catch(() => {
        addNotification({
          type: "error",
          title: `Failed to copy ${linkType} link`,
        });
        setOpen(false);
      });
  };

  const currentLink = window.location.href;
  const previewLink =
    initialPreviewHash || previewHash
      ? `${window.location.origin}/app/${type}s/preview/${
          initialPreviewHash || previewHash
        }`
      : "";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="buttonIcon" buttonIcon={<TbShare />}></Button>
            </TooltipTrigger>
            <TooltipContent>Share {type} link</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent className="p-4 space-y-4 divide-none">
        <div className="flex justify-between items-center">
          <p className="text-md font-medium">Share {type} link</p>
          <Button
            variant="buttonIcon"
            buttonIcon={<TbX />}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="flex-col justify-between items-center space-y-2">
          <div className="flex-col justify-between items-center">
            <div className="flex items-center w-full space-x-1">
              <TbEdit className="text-zinc-500" />
              <Label>Editable link</Label>
            </div>
            <p className="text-xs text-zinc-500 mb-1">
              Authenticated users with this link can view and edit the document
            </p>
            <Input value={currentLink} readOnly autoFocus={false} />
          </div>
          <Button
            onClick={() =>
              handleCopyLink(
                currentLink,
                `Editable ${type[0].toLowerCase() + type.slice(1)}`
              )
            }
            variant="primaryBlur"
            className="w-full"
            autoFocus
            startIcon={<TbCopy />}
          >
            Copy editable link
          </Button>
        </div>
        {type === "document" && (
          <div className="flex-col justify-between items-center space-y-2">
            <div className="flex-col justify-between items-center">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center w-full space-x-1">
                  <TbWorld className="text-zinc-500" />
                  <Label>Public link</Label>
                </div>
                {!initialPreviewHash &&
                  !previewHash &&
                  (generatePreview ? (
                    <Spinner size="sm" variant="primary" />
                  ) : (
                    <Switch
                      size="sm"
                      checked={generatePreview}
                      onCheckedChange={(checked) => {
                        setGeneratePreview(checked);
                        if (checked) {
                          generatePreviewHash();
                        }
                      }}
                    />
                  ))}
              </div>
              <p className="text-xs text-zinc-500 mb-1">
                Anyone with the link can view a read-only version of the
                document
              </p>
              {(initialPreviewHash || previewHash) && (
                <Input value={previewLink} readOnly autoFocus={false} />
              )}
            </div>
            {(initialPreviewHash || previewHash) && (
              <Button
                onClick={() =>
                  handleCopyLink(
                    previewLink,
                    `Public ${type[0].toLowerCase() + type.slice(1)}`
                  )
                }
                variant="primaryBlur"
                className="w-full"
                autoFocus
                startIcon={<TbCopy />}
              >
                Copy public link
              </Button>
            )}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
