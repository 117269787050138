import { DocumentEditorContainer } from "@/components/AdvancedEditor";
import { Spinner, useNewDocumentStore } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useEditorConflictDialogStore } from "@/stores/conflict";
import { useSerpStore } from "@/stores/serp";
import { useAiWizardDialogStore } from "@/stores/wizard";
import { useEffect, useMemo } from "react";
import { TbAlertCircleFilled } from "react-icons/tb";
import { useIsMutating } from "react-query";
import { useParams } from "react-router-dom";
import { DocumentHeader } from "../../../components/Layout/DocumentHeader";
import { useAuth } from "../../../lib/auth";
import {
  AiArticleLoader,
  useIsAiArticle,
  useIsAiArticleAccepted,
  useIsAiArticleGenerated,
} from "../../ai/components/AiArticleLoader";
import { useDocument } from "../api/getDocument";
import { isLastEditor } from "../utils/lastEditor";

export const Document = ({ isReadOnly = false }) => {
  const params = useParams<{ documentHash: string }>();
  const documentHash = params.documentHash;
  const documentQuery = useDocument({ documentHash: documentHash! });
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const isSubscriptionCancelled = subscriptionData?.status === "canceled";
  const { user } = useAuth();
  const isCreatingDocument = !!useIsMutating({
    mutationKey: ["createDocument"],
  });
  const { resetSerp } = useSerpStore();

  const isNewDocumentOpen = useNewDocumentStore((state) => state.open);
  const closeEditorConflictDialog = useEditorConflictDialogStore(
    (state) => state.closeDialog
  );
  const openEditorConflictDialog = useEditorConflictDialogStore(
    (state) => state.openDialog
  );
  const closeAiWizardDialog = useAiWizardDialogStore(
    (state) => state.closeDialog
  );
  const openAiWizardDialog = useAiWizardDialogStore(
    (state) => state.openDialog
  );

  const isAiArticle = useIsAiArticle();
  const isAiArticleAccepted = useIsAiArticleAccepted();
  const isAiArticleGenerated = useIsAiArticleGenerated();
  const isAiArticleStarted =
    (documentQuery.data && documentQuery.data?.metadata.ai_article_started) ||
    false;
  const isLastDocumentEditor = isLastEditor(
    documentQuery.data,
    user?.id,
    user?.fullName
  );
  const lastEditorFullName =
    (documentQuery.data && documentQuery.data?.metadata.last_editor_name) || "";
  const lastEditedTime =
    (documentQuery.data && documentQuery.data?.lastEdited) || 0;

  useMemo(() => {
    if (documentHash) {
      resetSerp();
    }
  }, [documentHash]);

  useEffect(() => {
    if (documentQuery.data && isAiArticleStarted && !isNewDocumentOpen) {
      closeEditorConflictDialog();
      closeAiWizardDialog();
      openAiWizardDialog("continueGeneratingAiArticle");
    } else {
      closeAiWizardDialog();
    }
  }, [documentHash, documentQuery.data]);

  // Handle Editor Conflict Dialog
  useEffect(() => {
    if (documentQuery.data && !isLastDocumentEditor && !isNewDocumentOpen) {
      closeAiWizardDialog();
      closeEditorConflictDialog();
      openEditorConflictDialog(
        "editorConflictOnLoad",
        lastEditorFullName,
        lastEditedTime
      );
    } else {
      closeEditorConflictDialog();
    }
  }, [documentHash, documentQuery.data]);

  if (documentQuery.isLoading || isLoadingSubscription || isCreatingDocument) {
    return (
      <div className="w-full h-[25%] flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!documentQuery.data && documentQuery.isSuccess) {
    return (
      <ContentLayout>
        <div className="flex h-full w-full items-center justify-center">
          <div className="text-center">
            <div className="flex items-center space-x-2 justify-center">
              <TbAlertCircleFilled className=" h-4 w-4 text-zinc-800 dark:text-zinc-200" />
              <p className="text-md font-medium text-zinc-800 dark:text-zinc-200">
                Document not found
              </p>
            </div>
            <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
              There is no document with the identifier{" "}
              <code className="font-mono text-zinc-800 dark:text-zinc-200">
                {documentHash}
              </code>
              .
            </p>
          </div>
        </div>
      </ContentLayout>
    );
  }

  if (!documentQuery.data) {
    return null;
  }

  if (isAiArticle && (!isAiArticleAccepted || !isAiArticleGenerated)) {
    return (
      <ContentLayout>
        <DocumentHeader renderTabs={false} renderOptions={false} />
        <AiArticleLoader />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      <DocumentEditorContainer
        documentMetadata={documentQuery.data}
        isReadOnly={isReadOnly || isSubscriptionCancelled}
        isSubscriptionCancelled={isSubscriptionCancelled}
      />
    </ContentLayout>
  );
};
