import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/Elements";
import {
  CountryField,
  LanguageField,
} from "@/components/Elements/Dialog/NewDocument";
import {
  countryOptions,
  languageOptions,
} from "@/components/Elements/Dialog/newDocumentOptions";
import { Head } from "@/components/Head";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useUser } from "@/features/auth";
import { useCreateDocument } from "@/features/documents/api/createDocument";
import { cn } from "@/utils/style";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TbInfoCircle, TbX } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const onboardingDocumentFormSchema = z.object({
  query: z.string().min(1),
  country: z.string(),
  language: z.string(),
});

type OnboardingDocumentFormValues = z.infer<
  typeof onboardingDocumentFormSchema
>;

const TITLE = "Create your first document" as string;

const SearchQueryField = ({ form }) => {
  const showFormMessage = form.formState.isSubmitted;

  return (
    <div>
      <FormField
        control={form.control}
        name={`query`}
        render={({ field, fieldState: { error } }) => (
          <FormItem>
            <FormLabel
              className={cn(
                "flex items-center text-zinc-600 dark:text-zinc-200 w-full justify-between"
              )}
            >
              <div className="flex items-center">Search Query</div>
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={`Enter a search query target (long-tail keywords work best)`}
                className="mb-2"
                autoFocus
              />
            </FormControl>
            {showFormMessage && <FormMessage>{error?.message}</FormMessage>}
          </FormItem>
        )}
      />
    </div>
  );
};

export const OnboardingNewDocument = () => {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const geography = JSON.parse(user?.geography || "{}");
  const trackEvent = useTrackEvent();

  const defaultValues: Partial<OnboardingDocumentFormValues> = {
    query: "",
    country: geography.code || "us",
    language: geography.lang || "en",
  };
  const form = useForm<OnboardingDocumentFormValues>({
    resolver: zodResolver(onboardingDocumentFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const handleTrackNewDocumentEvent = (query: string) => {
    trackEvent.mutate({
      event: "new_document",
      properties: JSON.stringify({
        type: "seo",
        query,
      }),
    });
  };

  const createDocument = useCreateDocument({
    notifyOnSuccess: false,
  });

  const handleCreateDocument = async (data: OnboardingDocumentFormValues) => {
    const res = await createDocument.mutateAsync({
      doc_owner: user.id,
      doc_owner_name: user.fullName,
      doc_status: 1,
      metadata: {
        name: data.query,
        code: data.country,
        display_code: countryOptions.find(
          (country) => country.value === data.country
        )?.label,
        lang_code: data.language,
        lang_display: languageOptions.find(
          (language) => language.value === data.language
        )?.label,
      },
      org_id: user.orgId,
      query: data.query,
      template: null,
      text: [
        {
          html: "",
          title: data.query,
          name: "Tab 1",
        },
      ],
      title: "",
    });
    /*queryClient.prefetchQuery(["googleSearchResults", data.query, false], () =>
      getGoogleSearchResults({
        queries: [
          {
            query: data.query,
            country: data.country,
            lang: data.language,
            count: 20,
          },
        ],
        doc_hash: res?.hash,
      })
    );*/
    handleTrackNewDocumentEvent(data.query);
    navigate(`/app/documents/${res?.hash}`, {
      replace: true,
      state: { onboardingDocument: true },
    });
    form.reset(defaultValues);
  };

  const handleTrackSkipOnboardingEvent = () => {
    trackEvent.mutate({
      event: "skip_onboarding",
      properties: JSON.stringify({
        step: 5,
      }),
    });
  };

  const handleSkip = () => {
    handleTrackSkipOnboardingEvent();
    navigate("/app/documents");
  };

  return (
    <>
      <Head title={TITLE} />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 dark:bg-hero-gradient space-y-8">
        <Button
          variant="buttonIcon"
          className="absolute top-4 right-4"
          buttonIcon={<TbX className="w-4 h-4" />}
          tooltipContent="Skip onboarding"
          onClick={handleSkip}
        ></Button>

        <div className="mx-auto max-w-[600px] space-y-4">
          <h2 className="flex font-medium text-2xl justify-center mt-4 text-zinc-900 dark:text-white">
            {TITLE}
          </h2>

          <p className="text-center text-zinc-700 dark:text-zinc-300 text-sm">
            Frase's AI will research your topic and help you outline, write and
            optimize your content in one place. Be as specific as possible with
            your query for best results.
          </p>
        </div>

        <div className="w-fit mx-auto">
          <div className="flex-col focus:[&:not(:focus-visible)]:outline-none focus:outline-none focus:ring-zinc-900/7.5 aria-selected:ring-transparent aria-selected:outline-none z-[51] gap-4 p-8 rounded-b-lg border dark:border-zinc-700 bg-white dark:bg-zinc-800 shadow-lg sm:rounded-lg sm:zoom-in-90 mx-auto">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleCreateDocument)}
                className="space-y-6 w-[450px]"
              >
                <div className="flex flex-col space-y-2 mb-4">
                  <SearchQueryField form={form} />
                </div>
                <div className="flex items-center w-full space-x-4 mb-4">
                  <CountryField form={form} />
                  <LanguageField form={form} />
                </div>
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex gap-x-2 justify-center w-full">
                    <Button
                      variant="outlineBlur"
                      onClick={() => navigate("/app/onboarding/workflow")}
                      className="w-fit"
                    >
                      Back
                    </Button>
                    <Button
                      variant="primaryBlur"
                      type="submit"
                      size="xs"
                      disabled={!form.formState.isDirty}
                      isLoading={createDocument.isLoading}
                    >
                      Create document
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>

        <div className="items-center flex bg-zinc-50 py-1 rounded-md border w-[450px] mx-auto dark:bg-emerald-900 dark:border-emerald-700">
          <div className="flex items-center justify-center mx-auto space-x-2">
            <TbInfoCircle className="w-4 h-4 text-zinc-400" />
            <p className="text-center items-center text-zinc-600 dark:text-white text-xs">
              Your free trial includes unlimited documents and two search
              queries.
            </p>
          </div>
        </div>

        <div className="text-center text-zinc-500 dark:text-zinc-400 text-xs mx-auto">
          Step 6 out of 6
        </div>
      </div>
    </>
  );
};
