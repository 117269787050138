import { Skeleton } from "@/components/Elements/Skeleton";
import { SubmitTicketMenuItem } from "@/features/help/components/SubmitTicketMenuItem";
import { useAuth } from "@/lib/auth";
import {
  ChartBarIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  MapIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { TbAppsFilled, TbArrowLeft, TbPlugConnected } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Elements";
import { NavigationGroup } from "../../../components/Layout/Navigation";
import { useSubscription } from "../../auth/api/getSubscription";

const settingsLinks = [
  { title: "Account", href: "/app/settings/account", logo: UserIcon },
  { title: "Team", href: "/app/settings/team", logo: UsersIcon },
  {
    title: "Plans",
    href: "/app/settings/plans",
    logo: MapIcon,
  },
  {
    title: "Subscription",
    href: "/app/settings/subscription",
    logo: CreditCardIcon,
  },
  {
    title: "Billing",
    href: "/app/settings/billing",
    logo: CurrencyDollarIcon,
  },
  {
    title: "Usage",
    href: "/app/settings/usage",
    logo: ChartBarIcon,
  },
  {
    title: "Integrations",
    href: "/app/settings/integrations",
    logo: TbAppsFilled,
  },
  {
    title: "API",
    href: "/app/settings/api",
    logo: TbPlugConnected,
  },
];

export const SettingsNavigation = () => {
  const navigate = useNavigate();
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { user } = useAuth();

  const isTeamPlan = subscriptionData?.plan?.includes("team");
  const isAppsumoUser = subscriptionData?.appsumo || false;
  const isAdmin = user?.org_role === 1;

  const filteredSettingsLinks = settingsLinks.filter((link) => {
    if (!isAdmin && !["Account", "Usage", "API"].includes(link.title)) {
      return false;
    }
    if (link.title === "Team" && !isTeamPlan) {
      return false;
    }
    if (link.title === "Plans" && isAppsumoUser) {
      return false;
    }
    if (
      ["Subscription", "Billing", "Usage"].includes(link.title) &&
      subscriptionData?.status === "inactive"
    ) {
      return false;
    }
    if (
      ["Subscription", "Usage"].includes(link.title) &&
      subscriptionData?.status === "canceled"
    ) {
      return false;
    }
    if (link.title === "API" && subscriptionData?.status === "inactive") {
      return false;
    }
    return true;
  });

  if (isLoadingSubscription) {
    return (
      <div className="flex flex-col h-screen bg-zinc-50 dark:bg-zinc-800 w-[220px] border-r dark:border-r-zinc-700 flex-shrink-0">
        <div className="mt-4 flex flex-col h-full ">
          <div className="flex px-1">
            <Button
              variant="text"
              onClick={() => {
                navigate("/app");
              }}
              buttonIcon={<TbArrowLeft />}
              textClassName="ml-2 text-start flex w-full text-zinc-900 dark:text-white"
              className="mx-2 w-full hover:bg-zinc-900/5 dark:hover:bg-white/5"
            >
              Settings
            </Button>
          </div>
          <div className="pt-3 px-3 space-y-2">
            {filteredSettingsLinks.map((group, groupIndex) => (
              <Skeleton key={groupIndex} className="h-6  bg-zinc-200" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-zinc-50 dark:bg-zinc-800 w-[220px] border-r dark:border-r-zinc-700 flex-shrink-0">
      <div className="mt-4 flex flex-col h-full justify-between">
        <div>
          <div className="flex px-1">
            <Button
              variant="text"
              onClick={() => {
                navigate("/app");
              }}
              buttonIcon={<TbArrowLeft />}
              textClassName="ml-2 text-start flex w-full text-zinc-900 dark:text-white"
              className="mx-2 w-full hover:bg-zinc-900/5 dark:hover:bg-white/5"
            >
              Settings
            </Button>
          </div>
          <div className="pt-3 pr-3">
            {filteredSettingsLinks.map((group, groupIndex) => (
              <NavigationGroup
                key={groupIndex}
                group={group}
                collapsible={false}
                variant="primary"
              />
            ))}
          </div>
        </div>
        <div className="pb-2 px-2">
          <SubmitTicketMenuItem />
        </div>
      </div>
    </div>
  );
};
