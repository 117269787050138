import frase_bot from "@/assets/frase_bot.png";
import { Button } from "@/components/Elements";
import { MagicCard } from "@/components/Elements/Card/MagicCard";
import { Layout } from "@/features/auth/components/Layout";
import { cn } from "@/utils/style";
import React, { useState } from "react";
import { TbFileText, TbWriting } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const contentTypes = [
  {
    variable: "short_form",
    display: "Short Form Content",
    description: "Email, Meta Descriptions, Social Posts",
    icon: <TbWriting />,
  },
  {
    variable: "long_form",
    display: "Long Form Content",
    description: "Articles, White Papers, Blogs",
    icon: <TbFileText />,
  },
];

export const OnboardingWorkflow = ({ onNext }) => {
  const [selectedContentType, setSelectedContentType] = useState("short_form");
  const navigate = useNavigate();

  const handleNext = () => {
    if (selectedContentType === "short_form") {
      navigate("/app/onboarding/select-tool");
    } else {
      navigate("/app/new-document");
    }
  };
  const isOptionSelected = selectedContentType !== "";

  return (
    <Layout
      className="flex flex-col items-center justify-center"
      showLogo={false}
    >
      <div className="flex flex-col items-center justify-center space-y-12">
        <div className="text-zinc-900 dark:text-white font-medium text-lg">
          <img
            src={frase_bot}
            alt="Frase Genius"
            className="rounded-xl w-12 h-12 border-2 start mb-4"
          />
          <p>What type of content do you primarily create?</p>
        </div>
        <div className="flex flex-row gap-2 w-full h-full">
          {contentTypes.map((option) => (
            <MagicCard
              key={option.variable}
              className={cn(
                "shadow-sm flex-1 h-56 w-full py-4 rounded-md bg-white px-4 cursor-pointer dark:bg-emerald-900/20",
                selectedContentType === option.variable
                  ? "ring-2 ring-inset ring-emerald-600 transition-all duration-300 bg-emerald-600/5 dark:ring-emerald-600 border-none"
                  : "transition-all duration-300 "
              )}
              gradientColor={"#059669"}
              gradientOpacity={0.1}
              onClick={() => setSelectedContentType(option.variable)}
            >
              <div className="flex flex-col w-full h-full items-center">
                {React.cloneElement(option.icon, {
                  className:
                    "w-8 h-8 flex-shrink-0 mt-6 mb-2 text-zinc-800 dark:text-white",
                })}
                <div className="flex text-sm font-medium text-zinc-700 dark:text-white mt-2">
                  {option.display}
                </div>
                <div className="text-2xs text-zinc-500 flex-shrink-0 mt-4 text-center items-start dark:text-zinc-300">
                  {option.description}
                </div>
              </div>
            </MagicCard>
          ))}
        </div>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex gap-x-2 justify-center w-full">
            <Button
              onClick={handleNext}
              className="w-full"
              disabled={!isOptionSelected}
            >
              Next
            </Button>
          </div>
          <div className="text-zinc-400 dark:text-zinc-600 text-xs mx-auto">
            Step 5 out of 6
          </div>
        </div>
      </div>
    </Layout>
  );
};
