import { useGetAiToolBookmarks } from "@/features/ai-tools/api/getAiToolBookmarks";
import { useGlobalTemplates } from "@/features/templates/api/getGlobalTemplates";
import { useGetTemplatesForOrg } from "@/features/templates/api/getTemplatesForOrg";
import { useAuth } from "@/lib/auth";
import { Bars3BottomLeftIcon, Bars4Icon } from "@heroicons/react/24/solid";
import {
  $unwrapMarkNode,
  $wrapSelectionInMarkNode,
  MarkNode,
} from "@lexical/mark";
import { $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isTableCellNode, $isTableRowNode } from "@lexical/table";
import {
  $createParagraphNode,
  $createRangeSelection,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  $nodesOfType,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  CommandListener,
  CommandListenerPriority,
  KEY_DOWN_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_SPACE_COMMAND,
  LexicalCommand,
  LexicalEditor,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { PencilLine } from "lucide-react";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextareaAutosize from "react-autosize-textarea";
import { createPortal } from "react-dom";
import {
  TbCheck,
  TbChevronLeft,
  TbChevronRight,
  TbCircleArrowRightFilled,
  TbClock,
  TbDots,
  TbMicrophone,
  TbReload,
  TbSparkles,
  TbTextWrap,
  TbTrash,
  TbX,
} from "react-icons/tb";
import {
  cancelGptChatStream,
  useGetGptChatStream,
} from "../../../../features/ai/api/getGptChatStream";
import {
  cancelGptChatStreamUsingUrls,
  useGetGptChatStreamUsingUrls,
} from "../../../../features/ai/api/getGptChatStreamUsingUrl";
import { useGetGptClassification } from "../../../../features/ai/api/getGptClassification";
import { getGptOutline } from "../../../../features/ai/api/getGptOutline";
import { getGptTitle } from "../../../../features/ai/api/getGptTitle";
import { useGetPromptHistory } from "../../../../features/ai/api/getPromptHistory";
import { useTrackEvent } from "../../../../features/analytics/api/trackUser";
import {
  useHandleInsertMarkdown,
  useHandleTransformNodes,
} from "../../../../features/documents/utils/pasting";
import { queryClient } from "../../../../lib/react-query";
import { useDocumentStore } from "../../../../stores/document";
import { useEditorStore } from "../../../../stores/editor";
import { useNotificationStore } from "../../../../stores/notifications";
import { useSerpStore } from "../../../../stores/serp";
import { cn } from "../../../../utils/style";
import {
  Button,
  Spinner,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../Elements";
import { ShortcutTag } from "../../../Elements/Tag/ShortcutTag";
import { PLAYGROUND_TRANSFORMERS } from "../MarkdownTransformers";
import { AiToolPanel } from "./AiToolPanel";
import { setGeneratingMenuPosition } from "./setGeneratingMenuPosition";
import { setMenuPosition } from "./setMenuPosition";
import { setSubmenuPosition } from "./setSubmenuPosition";
import { useEditorFocus } from "./useEditorFocus";

interface RewriteHistoryNavigatorProps {
  rewriteTextHistory: string[];
  rewriteTextHistoryIndex: number;
  setRewriteTextHistoryIndex: React.Dispatch<React.SetStateAction<number>>;
  setGeneratedRewriteText: React.Dispatch<React.SetStateAction<string>>;
}

const RewriteHistoryNavigator: React.FC<RewriteHistoryNavigatorProps> = ({
  rewriteTextHistory,
  rewriteTextHistoryIndex,
  setRewriteTextHistoryIndex,
  setGeneratedRewriteText,
}) => {
  return (
    <div className="flex items-center space-x-1 border-l h-5 my-auto ml-2 pl-2 border-zinc-300 dark:border-zinc-700">
      <Button
        variant="buttonIcon"
        size="xs"
        buttonIcon={<TbChevronLeft style={{ strokeWidth: 2.5 }} />}
        disabled={rewriteTextHistoryIndex === 0}
        onClick={() => {
          if (rewriteTextHistoryIndex > 0) {
            setRewriteTextHistoryIndex(rewriteTextHistoryIndex - 1);
            setGeneratedRewriteText(
              rewriteTextHistory[rewriteTextHistoryIndex - 1]
            );
          }
        }}
        tooltipContent="Previous rewrite"
      />
      <p className="text-2xs text-zinc-500 whitespace-nowrap">
        {rewriteTextHistoryIndex + 1} of {rewriteTextHistory.length}
      </p>
      <Button
        variant="buttonIcon"
        size="xs"
        buttonIcon={<TbChevronRight style={{ strokeWidth: 2.5 }} />}
        disabled={rewriteTextHistoryIndex >= rewriteTextHistory.length - 1}
        onClick={() => {
          if (rewriteTextHistoryIndex < rewriteTextHistory.length - 1) {
            setRewriteTextHistoryIndex(rewriteTextHistoryIndex + 1);
            setGeneratedRewriteText(
              rewriteTextHistory[rewriteTextHistoryIndex + 1]
            );
          }
        }}
        tooltipContent="Next rewrite"
      />
    </div>
  );
};

function useCommandSubscription<T>(
  command: LexicalCommand<T>,
  fn: CommandListener<T>,
  priority: CommandListenerPriority
): void {
  const [editor] = useLexicalComposerContext();
  useLayoutEffect(() => {
    return editor.registerCommand(command, fn, priority);
  }, [editor, command, fn, priority]);
}

const PencilIcon = () => (
  <PencilLine
    className="w-4 h-4 text-emerald-600 fill-emerald-600 flex-shrink-0"
    style={{ strokeWidth: 0.75 }}
  />
);

function getSeed(fraseDocument) {
  let seed = "";

  if (fraseDocument.query) {
    seed += `You are writing an article about ${fraseDocument.query}. `;
  }

  seed += `You write engaging content that follows a well organized structure with headings and relevant content about the topic. Your content is never repetitive and is easy to read.`;

  return seed;
}

function getClosestHeadingBeforeCursor(
  editor: LexicalEditor,
  lastSelection: RangeSelection | null
): Promise<string | null> {
  return new Promise((resolve) => {
    editor.update(() => {
      // Use a fresh selection if lastSelection is not provided
      const selection = lastSelection || $getSelection();
      if ($isRangeSelection(selection)) {
        let currentNode = selection.anchor.getNode();
        // get the top level node while parent exists
        while (currentNode && !$isRootNode(currentNode.getParent())) {
          currentNode = currentNode.getParent();
        }
        while (currentNode !== null) {
          if (
            $isHeadingNode(currentNode) &&
            currentNode.getTextContent().length > 0
          ) {
            resolve(currentNode.getTextContent());
            return; // Exit the update loop and resolve the promise
          }
          currentNode = currentNode.getPreviousSibling();
        }
      }
      resolve(null); // Resolve with null if no heading node is found
    });
  });
}

function getClosestSectionBeforeCursor(
  editor: LexicalEditor,
  lastSelection: RangeSelection | null
): Promise<string | null> {
  return new Promise((resolve) => {
    editor.update(() => {
      // Use a fresh selection if lastSelection is not provided
      const selection = lastSelection || $getSelection();
      if ($isRangeSelection(selection)) {
        let currentNode = selection.anchor.getNode();
        // get the top level node while parent exists
        while (currentNode && !$isRootNode(currentNode.getParent())) {
          currentNode = currentNode.getParent();
        }
        while (currentNode !== null) {
          if (
            $isParagraphNode(currentNode) &&
            currentNode.getTextContent().length > 0
          ) {
            resolve(currentNode.getTextContent());
            return; // Exit the update loop and resolve the promise
          }
          currentNode = currentNode.getPreviousSibling();
        }
      }
      resolve(null); // Resolve with null if no heading node is found
    });
  });
}

const seoOptions = [
  {
    label: "SEO Power-Ups",
    value: "label",
  },
  {
    label: "Title ideas",
    icon: <PencilIcon />,
    value:
      "Write a list of title ideas for the article. The title should be unique and compelling.",
    type: "write",
  },
  {
    label: "Article outline",
    icon: <PencilIcon />,
    value:
      "Write an outline for the article. The outline should include a list of headings and sub-headings.",
    type: "write",
  },
];

const recommendedOptions = [
  {
    label: "Write with AI",
    value: "label",
  },
  {
    label: "Continue writing",
    icon: <PencilIcon />,
    value: "Continue writing",
    type: "insert",
    shortcut: `${
      /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
        ? "⌘ + Enter"
        : "Ctrl + Enter"
    }`,
  },
  {
    label: "Section for a heading",
    icon: <PencilIcon />,
    value: "Write an article section for the following heading: {heading}",
    type: "insert",
  },
];

const rewritingOptions = [
  {
    label: "Edit or review selection",
    value: "label",
  },
  {
    label: "Improve writing",
    icon: (
      <TbSparkles className="w-4 h-4 text-emerald-600 stroke-emerald-600 fill-emerald-600 flex-shrink-0" />
    ),
    value: "Improve the following text while maintaining the same length",
    type: "rewrite",
  },
  /*{
    label: "Continue writing",
    icon: (
      <PencilIcon className="w-4 h-4 text-emerald-600 stroke-emerald-600 fill-emerald-600 flex-shrink-0" />
    ),
    value: "Continue writing the following text",
    type: "rewrite",
  },*/
  {
    label: "Make shorter",
    icon: (
      <Bars3BottomLeftIcon
        className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0"
        style={{
          strokeWidth: 0.5,
        }}
      />
    ),
    value:
      "Condense the following text to be no more than half the number of characters while maintaining the same meaning",
    type: "rewrite",
  },
  {
    label: "Make longer",
    icon: (
      <Bars4Icon
        className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0"
        style={{
          strokeWidth: 0.5,
        }}
      />
    ),
    value:
      "Expand the following text to be no more than double the number of characters while maintaining the same meaning",
    type: "rewrite",
  },
  {
    label: "Change tone",
    icon: (
      <TbMicrophone className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0" />
    ),
    value: "submenu",
    type: "submenu",
    submenu: [
      {
        icon: (
          <TbMicrophone className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0" />
        ),
        label: "Formal",
        value:
          "Rewrite the following text to be formal in tone while maintaining the same length",
        type: "rewrite",
      },
      {
        icon: (
          <TbMicrophone className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0" />
        ),
        label: "Informal",
        value:
          "Rewrite the following text to be informal in tone while maintaining the same length",
        type: "rewrite",
      },
      {
        icon: (
          <TbMicrophone className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0" />
        ),
        label: "Persuasive",
        value:
          "Rewrite the following text to be persuasive in tone while maintaining the same length",
        type: "rewrite",
      },
      {
        icon: (
          <TbMicrophone className="w-4 h-4 text-emerald-600 stroke-emerald-600  flex-shrink-0" />
        ),
        label: "Friendly",
        value:
          "Rewrite the following text to be friendly in tone while maintaining the same length",
        type: "rewrite",
      },
    ],
  },
];

const rewriteGenerationOptions = [
  {
    label: "Replace selection",
    icon: <TbCheck />,
    value: "replace",
  },
  {
    label: "Insert below",
    icon: <TbTextWrap />,
    value: "insert-below",
  },
  {
    label: "",
    value: "label",
  },
  {
    label: "Discard",
    icon: <TbTrash />,
    value: "discard",
  },
  {
    label: "Try Again",
    icon: <TbReload />,
    value: "tryagain",
  },
  {
    label: "Close",
    icon: <TbX />,
    value: "close",
    shortcut: "Esc",
  },
];

const generationOptions = [
  {
    label: "Done",
    icon: <TbCheck />,
    value: "done",
  },
  {
    label: "",
    value: "label",
  },
  {
    label: "Discard",
    icon: <TbTrash />,
    value: "discard",
  },
  {
    label: "Try Again",
    icon: <TbReload />,
    value: "tryagain",
  },
  {
    label: "Close",
    icon: <TbX />,
    value: "close",
    shortcut: "Esc",
  },
];

export function restoreLastSelection(
  editor: LexicalEditor,
  lastSelection: RangeSelection | null
) {
  editor.update(() => {
    if (lastSelection) {
      $setSelection(lastSelection.clone());
    } else {
      editor.focus();
    }
  });
}

const unwrapHighlightedMarkNodes = async (editor: LexicalEditor) => {
  editor.update(
    () => {
      const markNodes = $nodesOfType(MarkNode);
      markNodes.forEach((node) => {
        if (node.getIDs().includes("highlighted-content")) {
          $unwrapMarkNode(node);
        }
      });
    },
    { tag: "history-merge" }
  );
};

function unhighlightGeneratedContent(
  editor: LexicalEditor,
  generatedNodeKeys: []
) {
  for (const key of generatedNodeKeys) {
    const nodeElement = editor.getElementByKey(key);
    nodeElement?.classList.remove("highlighted-content");
  }
}

function useAiWriterMenu(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  linePlaceholderRef: React.RefObject<HTMLDivElement>,
  showMenu: boolean,
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
  lastSelection: RangeSelection | null,
  setLastSelection: React.Dispatch<React.SetStateAction<RangeSelection | null>>,
  isRewriting: boolean,
  setIsRewriting: React.Dispatch<React.SetStateAction<boolean>>,
  editableRef: React.RefObject<HTMLDivElement>
): JSX.Element | null {
  const { document: fraseDocument } = useDocumentStore();
  const { editor: editorStore } = useEditorStore();
  const { serp: serpStore } = useSerpStore();
  const { addNotification } = useNotificationStore();
  const trackEvent = useTrackEvent();
  const { user } = useAuth();

  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const commandRef = useRef<HTMLTextAreaElement>(null);
  const rewriteTextRef = useRef<HTMLTextAreaElement>(null);
  const subMenuRef = useRef<HTMLDivElement>(null);
  const subMenuItemsRef = useRef<HTMLDivElement>([]);

  const [promptHistory, setPromptHistory] = useState([]);
  const [isSerpContextEnabled, setIsSerpContextEnabled] = useState(false);
  const [previousCommandValue, setPreviousCommandValue] = useState("");
  const [commandValue, setCommandValue] = useState("");
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [isChatQueryEnabled, setIsChatQueryEnabled] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isInlineWriting, setIsInlineWriting] = useState(false);
  const [shouldStopAtNewLine, setShouldStopAtNewLine] = useState(false);
  const [lastHeadingBeforeSelection, setLastHeadingBeforeSelection] = useState<
    string | null
  >(null);
  const [lastSectionBeforeSelection, setLastSectionBeforeSelection] = useState<
    string | null
  >(null);
  const [initialSelection, setInitialSelection] =
    useState<RangeSelection | null>();
  const [isNewlineWriting, setIsNewlineWriting] = useState(false);
  const [isEmptyLine, setIsEmptyLine] = useState(false);
  const [submenuOptions, setSubmenuOptions] = useState([]);
  const [
    isInlineGeneratedTextNotInserted,
    setIsInlineGeneratedTextNotInserted,
  ] = useState(false);
  const [
    previousIsInlineGeneratedTextNotInserted,
    setPreviousIsInlineGeneratedTextNotInserted,
  ] = useState(isInlineGeneratedTextNotInserted);
  const [commandFocused, setCommandFocused] = useState(false);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [subMenuSelectedOptionIndex, setSubMenuSelectedOptionIndex] =
    useState(0);
  const [generatedNodeKeys, setGeneratedNodeKeys] = useState([]);
  const [generatedTableNodeKeys, setGeneratedTableNodeKeys] = useState(
    new Set<string>()
  );
  const [generatedRewriteText, setGeneratedRewriteText] = useState("");
  const [rewriteTextHistoryIndex, setRewriteTextHistoryIndex] = useState(0);
  const [rewriteTextHistory, setRewriteTextHistory] = useState<string[]>([]);
  const [chatContext, setChatContext] = useState("");

  const [generatedNodeText, setGeneratedNodeText] = useState([]);

  const [isSerpProcessed, setIsSerpProcessed] = useState(false);

  const [commandUrls, setCommandUrls] = useState([]);
  const [commandQuery, setCommandQuery] = useState("");
  const [commandQueryRewrites, setCommandQueryRewrites] = useState([]);
  const editorFocused = useEditorFocus();
  const assistantSeed = useMemo(() => getSeed(fraseDocument), [fraseDocument]);
  const serpUrls = serpStore[fraseDocument.id]?.urls ?? [];
  const { articles, topics } = serpStore[fraseDocument.id] || {
    articles: [],
    topics: [],
  };
  const { activeTabIndex } = editorStore;
  const title = fraseDocument.text[activeTabIndex]?.title || "";

  const { data: promptHistoryData, refetch: refetchPromptHistory } =
    useGetPromptHistory();

  const { data: globalTemplatesData, isLoading: isLoadingGlobalTemplates } =
    useGlobalTemplates({
      type: "ai",
      selectedType: "featured",
      user,
    });

  const { data: orgTemplatesData, isLoading: isLoadingOrgTemplates } =
    useGetTemplatesForOrg({
      org_id: user?.orgId!,
      type: "ai",
    });

  const {
    data: bookmarkedTemplatesData,
    isLoading: isLoadingBookmarkedTemplates,
    refetch: refetchBookmarkedTemplates,
  } = useGetAiToolBookmarks({});

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showAiToolPanel, setShowAiToolPanel] = useState(false);
  const [showAiWriterInput, setShowAiWriterInput] = useState(true);
  const [showAiWriterMenu, setShowAiWriterMenu] = useState(true);
  const [templatesFieldsData, setTemplatesFieldsData] = useState({});
  const [isAiToolOutputLoading, setIsAiToolOutputLoading] = useState(false);

  const handleTemplateSelection = (template) => {
    const isBookmarked = bookmarkedTemplatesData.some(
      (bookmarkedTemplate) => bookmarkedTemplate.hash === template.hash
    );
    setSelectedTemplate({ ...template, isBookmarked });
    setShowAiToolPanel(true);
    setShowAiWriterInput(false);
    setShowAiWriterMenu(false);
    setTemplatesFieldsData({});
    setCommandValue("");
  };

  useEffect(() => {
    if (serpStore[fraseDocument.id]?.serpLoaded) {
      setIsSerpProcessed(true);
    } else {
      setIsSerpProcessed(false);
    }
  }, [serpStore[fraseDocument.id]?.serpLoaded]);

  useEffect(() => {
    if (promptHistoryData) {
      setPromptHistory(promptHistoryData);
    }
  }, [promptHistoryData]);

  useEffect(() => {
    subMenuItemsRef.current = subMenuItemsRef.current?.slice(
      0,
      promptHistoryData?.length || 0
    );
  }, [promptHistoryData]);

  //useAutosizeTextArea(commandRef.current, commandValue, () => {});

  const recentPromptOptions = useMemo(() => {
    return promptHistoryData?.slice(3).map((prompt) => ({
      label: prompt.prompt,
      value: prompt.prompt,
      type: "insert",
      icon: <PencilIcon />,
    }));
  }, [promptHistoryData]);

  const aiToolOptions = useMemo(() => {
    if (!orgTemplatesData && !bookmarkedTemplatesData) return seoOptions;

    const templateMap = new Map();

    // Add bookmarked templates to the map
    (bookmarkedTemplatesData || []).forEach((template) => {
      templateMap.set(template.hash, template);
    });

    // Add org templates to the map, avoiding duplicates
    (orgTemplatesData || []).forEach((template) => {
      if (!templateMap.has(template.hash)) {
        templateMap.set(template.hash, template);
      }
    });

    // Add global templates to the map, avoiding duplicates
    (globalTemplatesData || []).forEach((template) => {
      if (!templateMap.has(template.hash)) {
        templateMap.set(template.hash, template);
      }
    });

    const templates = Array.from(templateMap.values()).map((template) => ({
      label: template.text[0].title,
      icon: <PencilIcon />,
      value: template,
      type: "template",
      onClick: () => handleTemplateSelection(template),
    }));

    const firstThreeTemplates = templates.slice(0, 3);
    const remainingTemplates = templates.slice(3);

    if (templates.length === 0) return seoOptions;

    return [
      {
        label: "AI Tools",
        value: "label",
      },
      ...firstThreeTemplates,
      ...(remainingTemplates.length > 0
        ? [
            {
              label: "Show more",
              type: "submenu",
              value: "show more",
              icon: <TbDots className="w-4 h-4 text-zinc-500 flex-shrink-0" />,
              submenu: remainingTemplates,
            },
          ]
        : []),
    ];
  }, [orgTemplatesData, bookmarkedTemplatesData, globalTemplatesData]);

  const commandOptions = useMemo(() => {
    // Provide a default empty array if promptHistory is undefined
    const safePromptHistory = promptHistoryData || [];

    // Extract the values from existing options to filter them out from the prompt history
    const existingOptionsValues = new Set(
      [...recommendedOptions, ...generationOptions, ...aiToolOptions].map(
        (option) => option.label
      )
    );

    // Get the three most recent unique prompts from the prompt history
    let recentPrompts = safePromptHistory
      .filter((prompt) => !existingOptionsValues.has(prompt.prompt))
      .slice(0, 3)
      .map((prompt) => ({
        label: prompt.prompt,
        value: prompt.prompt,
        type: "insert", // Assuming all prompts are of type "write"
        icon: (
          <TbClock className="w-4 h-4 text-emerald-600 stroke-emerald-600 flex-shrink-0" />
        ),
      }));

    if (safePromptHistory.length > 3) {
      recentPrompts.push({
        label: "Show more",
        type: "submenu",
        value: "show more",
        icon: <TbDots className="w-4 h-4 text-zinc-500 flex-shrink-0" />,
        submenu: safePromptHistory.map((prompt) => ({
          label: prompt.prompt,
          value: prompt.prompt,
          type: "write", // Assuming all prompts are of type "write"
          icon: (
            <TbClock className="w-4 h-4 text-emerald-600 stroke-emerald-600 flex-shrink-0" />
          ),
        })),
      });
    }

    if (recentPrompts.length > 0) {
      recentPrompts.unshift({
        label: "Recents",
        value: "label",
      });
    }

    if (isRewriting && !isGenerated && commandValue.trim() === "") {
      return rewritingOptions.filter((option) =>
        option.label.toLowerCase().includes(commandValue.toLowerCase())
      );
    } else if (isRewriting && isGenerated && commandValue.trim() === "") {
      return rewriteGenerationOptions.filter(
        (option) =>
          option.label.toLowerCase().includes(commandValue.toLowerCase()) &&
          !(selectedTemplate && option.value === "replace")
      );
    }

    // Combine the recent prompts with the existing command options logic
    if (commandValue.length > 0 && commandValue.trim() !== "") {
      setSelectedOptionIndex(0);
      setSubMenuSelectedOptionIndex(0);
      return [
        ...recommendedOptions.filter(
          (option) =>
            option.value !== "label" &&
            option.label
              .toLowerCase()
              .includes(commandValue.trim().toLowerCase())
        ),
        ...aiToolOptions.filter(
          (option) =>
            option.value !== "label" &&
            option.label
              .toLowerCase()
              .includes(commandValue.trim().toLowerCase())
        ),
        ...recentPrompts.filter(
          (option) =>
            option.value !== "label" &&
            option.label
              .toLowerCase()
              .includes(commandValue.trim().toLowerCase()) &&
            option.label.toLowerCase() !== commandValue.trim().toLowerCase()
        ),
        ...(recentPrompts
          .find((prompt) => prompt.value === "show more")
          ?.submenu.filter(
            (option) =>
              option.label
                .toLowerCase()
                .includes(commandValue.trim().toLowerCase()) &&
              option.label.toLowerCase() !== commandValue.trim().toLowerCase()
          ) || []),
        ...(aiToolOptions
          .find((option) => option.value === "show more")
          ?.submenu.filter(
            (option) =>
              option.label
                .toLowerCase()
                .includes(commandValue.trim().toLowerCase()) &&
              option.label.toLowerCase() !== commandValue.trim().toLowerCase()
          ) || []),
      ];
    } else if (isGenerated && commandValue.trim() === "") {
      const continueWritingOption = {
        label: "Continue writing",
        icon: <PencilIcon />,
        value: "Continue writing",
        type: "write",
      };

      if (previousCommandValue === "Continue writing" || isInlineWriting) {
        return [
          generationOptions[0],
          continueWritingOption,
          ...generationOptions.slice(1),
        ];
      }

      return [...generationOptions];
    } else {
      return [...recommendedOptions, ...aiToolOptions, ...recentPrompts]; // Flatten the array here
    }
  }, [
    commandValue,
    isGenerated,
    promptHistory,
    promptHistoryData,
    showMenu,
    isRewriting,
    aiToolOptions,
    bookmarkedTemplatesData,
    templatesFieldsData,
    selectedTemplate,
  ]);

  // Create refs for state variables
  const commandOptionsRef = useRef(commandOptions);
  const previousCommandValueRef = useRef(previousCommandValue);
  const commandValueRef = useRef(commandValue);
  const generatedRewriteTextRef = useRef(generatedRewriteText);
  const isGeneratedRef = useRef(isGenerated);
  const isGeneratingRef = useRef(isGenerating);
  const selectedOptionIndexRef = useRef(selectedOptionIndex);
  const editorFocusedRef = useRef(false);
  const showMenuRef = useRef(showMenu);
  const showSubmenuRef = useRef(showSubmenu);
  const submenuOptionsRef = useRef(submenuOptions);
  const recentPromptOptionsRef = useRef(recentPromptOptions);
  const subMenuSelectedOptionIndexRef = useRef(subMenuSelectedOptionIndex);
  const generatedNodeKeysRef = useRef(generatedNodeKeys);
  const generatedNodeTextRef = useRef(generatedNodeText);
  const lastSelectionRef = useRef(lastSelection);
  const isRewritingRef = useRef(isRewriting);
  const showAiToolPanelRef = useRef(showAiToolPanel);
  const isAiToolOutputLoadingRef = useRef(isAiToolOutputLoading);

  const resetState = ({ shouldRestoreLastSelection = true } = {}) => {
    //if (!isInlineWriting) {
    //  handleTransformNodes([], setLastSelection);
    //}

    setMaxTokens(1000);
    setIsInlineWriting(false);
    setIsNewlineWriting(false);
    setIsEmptyLine(false);
    setInitialSelection(null);

    setPreviousIsInlineGeneratedTextNotInserted(false);
    setIsInlineGeneratedTextNotInserted(false);
    cancelGptChatStream();
    cancelGptChatStreamUsingUrls();
    setIsChatQueryEnabled(false);
    setIsGenerated(false);
    setIsGenerating(false);
    setPreviousCommandValue("");
    setCommandValue("");
    setGeneratedRewriteText("");
    setRewriteTextHistory([]);
    setRewriteTextHistoryIndex(0);
    setSelectedOptionIndex(1);
    setCommandFocused(false);
    setShowMenu(false);
    setIsRewriting(false);
    setShowSubmenu(false);
    setSubmenuOptions([]);
    if (shouldRestoreLastSelection) {
      restoreLastSelection(editor, lastSelectionRef.current);
    }
    unwrapHighlightedMarkNodes(editor);
    unhighlightGeneratedContent(editor, generatedNodeKeysRef.current);
    setGeneratedNodeKeys([]);
    setGeneratedNodeText([]);
    setGeneratedTableNodeKeys(new Set<string>());
    setShouldStopAtNewLine(false);

    setSubMenuSelectedOptionIndex(0);
    setShowAiWriterInput(true);
    setShowAiWriterMenu(true);
    setShowAiToolPanel(false);
    setTemplatesFieldsData({});
    setSelectedTemplate(null);
    setIsAiToolOutputLoading(false);
    commandOptionsRef.current = [];
    commandValueRef.current = "";
    previousCommandValueRef.current = "";
    isGeneratedRef.current = false;
    isGeneratingRef.current = false;
    selectedOptionIndexRef.current = 0;
    showMenuRef.current = false;
    showSubmenuRef.current = false;
    submenuOptionsRef.current = [];
    recentPromptOptionsRef.current = [];
    subMenuSelectedOptionIndexRef.current = 0;
    lastSelectionRef.current = null;
    generatedNodeKeysRef.current = [];
    generatedNodeTextRef.current = [];
    generatedRewriteTextRef.current = "";
    isRewritingRef.current = false;
    showAiToolPanelRef.current = false;
    isAiToolOutputLoadingRef.current = false;

    queryClient.removeQueries(["gptChatStream"], { exact: false });
  };

  // Update the refs whenever the state changes
  useEffect(() => {
    commandOptionsRef.current = commandOptions;
    commandValueRef.current = commandValue;
    previousCommandValueRef.current = previousCommandValue;
    isGeneratedRef.current = isGenerated;
    isGeneratingRef.current = isGenerating;
    selectedOptionIndexRef.current = selectedOptionIndex;
    showMenuRef.current = showMenu;
    showSubmenuRef.current = showSubmenu;
    submenuOptionsRef.current = submenuOptions;
    editorFocusedRef.current = editorFocused;
    recentPromptOptionsRef.current = recentPromptOptions;
    subMenuSelectedOptionIndexRef.current = subMenuSelectedOptionIndex;
    generatedNodeKeysRef.current = generatedNodeKeys;
    generatedNodeTextRef.current = generatedNodeText;
    lastSelectionRef.current = lastSelection;
    generatedRewriteTextRef.current = generatedRewriteText;
    isRewritingRef.current = isRewriting;
    showAiToolPanelRef.current = showAiToolPanel;
    isAiToolOutputLoadingRef.current = isAiToolOutputLoading;
  }, [
    commandValue,
    previousCommandValue,
    isGenerated,
    isGenerating,
    selectedOptionIndex,
    commandOptions,
    showMenu,
    showSubmenu,
    submenuOptions,
    editorFocused,
    recentPromptOptions,
    subMenuSelectedOptionIndex,
    generatedNodeKeys,
    generatedNodeText,
    lastSelection,
    generatedRewriteText,
    isRewriting,
    showAiToolPanel,
    isAiToolOutputLoading,
  ]);

  const handleOnInsert = useHandleInsertMarkdown();
  const handleTransformNodes = useHandleTransformNodes();

  function convertGeneratedTextToMarkNodes(
    editor: LexicalEditor,
    insertedNodeText: string[]
  ) {
    editor.update(
      () => {
        const editorTextNodes = $getRoot().getAllTextNodes();

        for (const generatedText of insertedNodeText) {
          for (const textNode of editorTextNodes) {
            const textContent = textNode.getTextContent();
            const generatedTextTrimmed = generatedText.trim();
            // Find all occurrences of the generatedText within the textContent
            let startIndex = textContent.indexOf(generatedTextTrimmed);
            if (startIndex !== -1) {
              let endIndex = startIndex + generatedTextTrimmed.length;

              // Create a selection range for this occurrence
              const selection = $createRangeSelection();
              selection.setTextNodeRange(
                textNode,
                startIndex,
                textNode,
                endIndex
              );
              $wrapSelectionInMarkNode(selection, false, "highlighted-content");
            }
          }
        }
      },
      {
        tag: "history-merge",
      }
    );
  }

  function highlightGeneratedContent(
    editor: LexicalEditor,
    generatedNodeKeys: []
  ) {
    editor.update(
      () => {
        for (const key of generatedNodeKeys) {
          const nodeElement = editor.getElementByKey(key);
          if (nodeElement) {
            nodeElement?.classList.add("highlighted-content");
          }
        }
      },
      {
        tag: "history-merge",
      }
    );
  }

  const handleInsertGeneratedText = async (
    generatedText: string,
    shouldInsertParagraph: boolean
  ) => {
    if (isInlineWriting) {
      // Check if the generatedText has a space at the start
      const generatedTextStartsWithSpace = generatedText.startsWith(" ");
      if (!generatedTextStartsWithSpace) {
        generatedText = ` ${generatedText}`;
      }
    }

    await smoothScrollIntoView(inputRef, editableRef, 100);

    await editor.update(() => {
      $setSelection(lastSelectionRef.current);
    });

    handleOnInsert(
      generatedText,
      shouldInsertParagraph,
      setLastSelection,
      isInlineWriting
    ).then((response) => {
      const {
        insertedNodeKeys,
        insertedTableNodeKeys,
        insertedNodeText,
        insertedLastSelection,
      } = response;

      setGeneratedNodeKeys((prevNodeKeys) => {
        const newKeys = [...prevNodeKeys, ...insertedNodeKeys];
        return newKeys;
      });
      setGeneratedTableNodeKeys(
        (prevNodeKeys) => new Set([...prevNodeKeys, ...insertedTableNodeKeys])
      );
      setGeneratedNodeText((prevContent) => {
        const newText = [...prevContent, ...insertedNodeText];
        return newText;
      });
      setIsInlineGeneratedTextNotInserted((prev) => {
        if (prev === true) {
          convertGeneratedTextToMarkNodes(editor, insertedNodeText);
          if (!isInlineWriting) {
            handleInsertNewParagraph();
          }
          return false;
        } else {
          if (!isRewriting && !selectedTemplate) {
            highlightGeneratedContent(editor, insertedNodeKeys);
          }
          return false;
        }
      });
      setLastSelection(insertedLastSelection);
    });
  };

  const replaceGeneratedText = async (generatedText: string) => {
    editor.update(
      () => {
        const markNodes = $nodesOfType(MarkNode);
        let lastMarkNode = null;

        // Remove all mark nodes and their parents, except the last one
        markNodes.forEach((markNode, index) => {
          if (index < markNodes.length - 1) {
            // Check if it's not the last mark node
            const parentNode = markNode.getParent();
            if (parentNode) {
              parentNode.remove(); // Remove the parent node
            }
          } else {
            lastMarkNode = markNode; // Save the last mark node
          }
        });

        if (lastMarkNode) {
          const selection = lastMarkNode.select().clone();
          $setSelection(selection);
          lastMarkNode.remove();
          if ($isRangeSelection(selection)) {
            const parentNode = selection.anchor.getNode();
            if (parentNode) {
              $setSelection(parentNode.selectEnd());
            }
            selection.removeText();
            selection.insertText(generatedText);
            setLastSelection(selection);
          }
        }
      },
      {
        tag: "history-merge",
      }
    );
  };

  const insertGeneratedTextBelow = async (generatedText: string) => {
    editor.update(
      () => {
        const markNodes = $nodesOfType(MarkNode);
        if (markNodes.length > 0) {
          $setSelection(markNodes[markNodes.length - 1].select().clone());
          setLastSelection(markNodes[markNodes.length - 1].select().clone());
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const paragraphNode = $createParagraphNode();
            $insertNodes([paragraphNode]);
            $setSelection(paragraphNode.select());
            setLastSelection(paragraphNode.select());
            selection.insertText(generatedText);
          }
        }
      },
      {
        tag: "history-merge",
      }
    );
  };

  const removeGeneratedNodes = async () => {
    editor.update(() => {
      const markNodes = $nodesOfType(MarkNode);
      markNodes.forEach((node) => {
        if (node.getIDs().includes("highlighted-content")) {
          const previousNode =
            node?.getPreviousSibling() || $getRoot().getFirstChild();
          $setSelection(previousNode?.selectEnd() || $getRoot().select());
          setLastSelection(previousNode?.selectEnd() || $getRoot().select());
          node?.remove();
        }
      });

      const combinedNodeKeys = new Set([
        ...Array.from(generatedNodeKeysRef.current),
        ...Array.from(generatedTableNodeKeys),
      ]);

      Array.from(combinedNodeKeys)
        .reverse()
        .forEach((nodeKey) => {
          const node = $getNodeByKey(nodeKey);
          if (node) {
            const previousNode =
              node?.getPreviousSibling() || $getRoot().getFirstChild();
            $setSelection(previousNode?.selectEnd() || $getRoot().select());
            setLastSelection(previousNode?.selectEnd() || $getRoot().select());
            node?.remove();
          }
        });

      if (!isInlineWriting) {
        handleInsertNewParagraph();
      }

      setGeneratedNodeKeys([]);
      setGeneratedNodeText([]);
      setGeneratedTableNodeKeys(new Set<string>());
    });
  };

  const handleInsertNewParagraph = async () => {
    editor.update(() => {
      const paragraphNode = $createParagraphNode();
      $insertNodes([paragraphNode]);
      $setSelection(paragraphNode.select());
      setLastSelection(paragraphNode.select());
    });
  };

  // Define state to track if the classification query has been successful
  const [shouldClassify, setShouldClassify] = useState(false);

  useEffect(() => {
    if (
      subMenuItemsRef.current &&
      subMenuItemsRef.current[subMenuSelectedOptionIndex]
    ) {
      subMenuItemsRef.current[subMenuSelectedOptionIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [subMenuSelectedOptionIndex]);

  // Trigger classification when the commandValue changes and is not empty
  useEffect(() => {
    const trimmedCommandValue = commandValue.trim();
    setShouldClassify(trimmedCommandValue.length > 0);
  }, [commandValue]);

  useEffect(() => {
    if (showMenu) {
      refetchPromptHistory();
    }
  }, [isGenerated, showMenu]);

  useEffect(() => {
    if (isSerpProcessed) {
      setIsSerpContextEnabled(true);
    }
  }, [isSerpProcessed]);

  const handleTrackEvent = (prompt: string, type: string) => {
    trackEvent.mutate({
      event: "ai_writer",
      properties: JSON.stringify({
        prompt,
        type,
        serp_context: isSerpContextEnabled,
      }),
    });
  };

  const handleTrackPowerupEvent = (powerup: string) => {
    trackEvent.mutate({
      event: "ai_writer_powerup",
      properties: JSON.stringify({
        name: powerup,
      }),
    });
  };

  const updateAiWriterMenuPosition = (shouldScrollIntoView = true) => {
    if (showMenu && linePlaceholderRef.current && inputRef.current) {
      if (isGenerating && generatedNodeKeys.length > 0) {
        setGeneratingMenuPosition(
          inputRef.current,
          anchorElem,
          linePlaceholderRef.current
        );
        commandRef.current?.focus();
      } else {
        setMenuPosition(
          inputRef.current,
          anchorElem,
          linePlaceholderRef.current
        );
        commandRef.current?.focus();
      }
      setTimeout(() => {
        smoothScrollIntoView(menuRef, editableRef, 200);
      }, 0);
    } else {
      editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          const nodes = selection.getNodes();
          if (nodes.length > 0) {
            const selectionKey = nodes[0].getKey();
            const selectionElem = editor.getElementByKey(selectionKey);
            if (selectionElem) {
              const selectionRect = selectionElem.getBoundingClientRect();
              const inputRect = inputRef.current?.getBoundingClientRect();
              const anchorRect = anchorElem?.getBoundingClientRect();

              if (inputRef.current && selectionRect && inputRect) {
                const topPosition = selectionRect.bottom - anchorRect.top - 16;
                if (generatedNodeKeysRef.current.length > 0) {
                  inputRef.current.style.transition = "transform 0.2s";
                }
                inputRef.current.style.transform = `translate(24px, ${topPosition}px)`;
                inputRef.current.style.width = `100%`;
              }
            }
          }
        }
      });
      commandRef.current?.focus();
      setTimeout(() => {
        smoothScrollIntoView(menuRef, editableRef, 200);
      }, 0);
    }

    if (shouldScrollIntoView) {
      setTimeout(() => {
        smoothScrollIntoView(menuRef, editableRef, 200);
      }, 0);
    }
  };

  const focusOnAiWriterMenu = () => {
    if (commandRef.current) {
      commandRef.current.focus();
    }
  };

  // Classification query
  const classifyChatQuery = useGetGptClassification({
    prompt: commandValue,
    lang: "en",
    isChatQueryEnabled: isChatQueryEnabled,
    docHash: fraseDocument.hash,
    config: {
      enabled: isChatQueryEnabled && shouldClassify && !selectedTemplate,
      staleTime: Infinity,
      retry: false,
    },
  });

  const isQueryNone =
    classifyChatQuery.data?.classification?.query === null ||
    classifyChatQuery.data?.classification?.query === "none";

  // gpt_chat_stream_using_urls query
  const generateStreamQueryUsingUrls = useGetGptChatStreamUsingUrls({
    urls: commandUrls,
    query: commandQuery,
    lang: "en",
    prompt: commandValue,
    query_rewrites: commandQueryRewrites,
    config: {
      enabled: false,
      staleTime: Infinity,
      retry: false,
      onError: (error) => {
        resetState();
        setShowMenu(true);
        setCommandValue(commandValue);
        setGeneratedNodeKeys([]);
        setGeneratedNodeText([]);
        setGeneratedTableNodeKeys(new Set<string>());

        setIsGenerating(true);

        generateChatQuery.refetch();
        restoreLastSelection(editor, lastSelectionRef.current);
      },
    },
    onDataReceived: handleInsertGeneratedText,
    onDownloadComplete: () => {
      setMaxTokens(1000);
      handleTrackEvent(commandValueRef.current, "gpt_chat_stream_using_urls");
      setIsChatQueryEnabled(false);
      setPreviousCommandValue(commandValueRef.current);
      setIsGenerating(false);
      setIsGenerated(true);
      setCommandValue("");
      setSelectedOptionIndex(0);
      setGeneratedNodeKeys((prevNodeKeys) => {
        const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
        // Then, pass the new keys to handleTransformNodes
        // Return the new keys to update the state
        return newKeys;
      });
      setGeneratedTableNodeKeys((prevNodeKeys) => {
        const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
        // Then, pass the new keys to handleTransformNodes
        if (!isInlineWriting) {
          const uniqueKeys = new Set(generatedNodeKeysRef.current);
          const shouldSetLastSelection = uniqueKeys.size === 1;
          handleTransformNodes(
            newKeys,
            shouldSetLastSelection,
            setLastSelection
          ).then(() => {
            if (!shouldSetLastSelection) {
              restoreLastSelection(editor, lastSelectionRef.current);
              setTimeout(() => {
                updateAiWriterMenuPosition();
              }, 0);
              setTimeout(() => {
                smoothScrollIntoView(menuRef, editableRef, 200);
              });
            }
          });
        }
        // Return the new keys to update the state
        return newKeys;
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        updateAiWriterMenuPosition();
      }, 0);
    },
  });

  const generateChatQuery = useGetGptChatStream({
    assistant_seed: assistantSeed,
    previous_messages: [
      {
        role: "user",
        content: chatContext,
      },
      ...(commandValue.length > 0
        ? [{ role: "user", content: commandValue }]
        : []),
    ],
    max_tokens: maxTokens,
    stop_at_newline: false,
    config: {
      enabled: false,
      staleTime: Infinity,
      retry: false,
    },
    onDataReceived: handleInsertGeneratedText,
    onDownloadComplete: () => {
      setMaxTokens(1000);
      handleTrackEvent(commandValueRef.current, "gpt_chat_stream");
      setIsChatQueryEnabled(false);
      setPreviousCommandValue(commandValueRef.current);
      setIsGenerating(false);
      setIsGenerated(true);
      setCommandValue("");
      setSelectedOptionIndex(0);
      setGeneratedNodeKeys((prevNodeKeys) => {
        const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
        // Then, pass the new keys to handleTransformNodes
        // Return the new keys to update the state
        return newKeys;
      });
      setGeneratedTableNodeKeys((prevNodeKeys) => {
        const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
        // Then, pass the new keys to handleTransformNodes
        if (!isInlineWriting) {
          const uniqueKeys = new Set(generatedNodeKeysRef.current);
          const shouldSetLastSelection = uniqueKeys.size === 1;
          handleTransformNodes(
            newKeys,
            shouldSetLastSelection,
            setLastSelection
          ).then(() => {
            if (!shouldSetLastSelection) {
              restoreLastSelection(editor, lastSelectionRef.current);
              setTimeout(() => {
                updateAiWriterMenuPosition();
              }, 0);
              setTimeout(() => {
                smoothScrollIntoView(menuRef, editableRef, 200);
              });
            }
          });
        }
        // Return the new keys to update the state
        return newKeys;
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        updateAiWriterMenuPosition();
      }, 0);
    },
  });

  const handleTitleIdeasSelection = async () => {
    setIsGenerating(true);
    getGptTitle({
      query: fraseDocument.query,
      lang: fraseDocument?.metadata?.lang_code,
      serp: articles.map((article) => {
        return {
          title: article.title,
          url: article.url,
          snippet: article.clean_text?.slice(0, 50) + "...",
          author: article.author,
          date_created: article.dateCreated,
          image: (article.images?.length > 0 && article.images[0]) || "",
        };
      }),
      docHash: fraseDocument.hash,
    }).then(async (response) => {
      restoreLastSelection(editor, lastSelection);
      const markdownTitle = response.titles
        .map((title) => {
          return `${title}`;
        })
        .join("\n");

      // Insert the fetched title content into the editor
      const insertionPromises = markdownTitle.split("\n").map((char, index) => {
        if (index === markdownTitle.split("\n").length - 1) {
          return handleInsertGeneratedText(char, false);
        } else {
          return handleInsertGeneratedText(char, true);
        }
      });

      // Wait for all insertions to complete
      Promise.all(insertionPromises).then(() => {
        handleTrackPowerupEvent("title_ideas");

        setIsChatQueryEnabled(false);
        setPreviousCommandValue(commandValue);
        setIsGenerating(false);
        setIsGenerated(true);
        setCommandValue("");
        setSelectedOptionIndex(0);
        setGeneratedNodeKeys((prevNodeKeys) => {
          const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
          // Then, pass the new keys to handleTransformNodes
          updateAiWriterMenuPosition();
          // Return the new keys to update the state
          return newKeys;
        });
        setGeneratedTableNodeKeys((prevNodeKeys) => {
          const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
          // Then, pass the new keys to handleTransformNodes
          updateAiWriterMenuPosition();
          // Return the new keys to update the state
          return newKeys;
        });
      });
    });
  };

  const handleArticleOutlineSelection = async () => {
    setIsGenerating(true); // Set generating state to true to show loading indicator
    getGptOutline({
      title: title,
      topics:
        topics && topics.length > 0
          ? topics.map((topic) => topic.entity).join(", ")
          : "",
      serp: articles.map((article) => {
        return {
          title: article.title,
          url: article.url,
          snippet: article.clean_text?.slice(0, 50) + "...",
          author: article.author,
          date_created: article.dateCreated,
          image: (article.images?.length > 0 && article.images[0]) || "",
        };
      }),
      lang: fraseDocument?.metadata?.lang_code || "en",
      serp: articles.map((article) => {
        return {
          title: article.title,
          url: article.url,
          snippet: article.clean_text?.slice(0, 50) + "...",
          author: article.author,
          date_created: article.dateCreated,
          image: (article.images?.length > 0 && article.images[0]) || "",
        };
      }),
      docHash: fraseDocument.hash,
    }).then(async (response) => {
      restoreLastSelection(editor, lastSelection);

      // Insert the fetched outline content into the editor
      // convert the response to one markdown string
      const markdownOutline = response.outline
        .map(
          (item) =>
            `${"#".repeat(parseInt(item.user_header_tag.substring(1)))} ${
              item.header
            }`
        )
        .join("\n\n");

      const insertionPromises = markdownOutline
        .split("\n")
        .map((char, index) => {
          if (index === markdownOutline.split("\n").length - 1) {
            return handleInsertGeneratedText(char, false);
          } else {
            return handleInsertGeneratedText(char, true);
          }
        });

      // Wait for all insertions to complete
      Promise.all(insertionPromises).then(() => {
        handleTrackPowerupEvent("article_outline");

        setIsChatQueryEnabled(false);
        setPreviousCommandValue(commandValue);
        setIsGenerating(false);
        setIsGenerated(true);
        setCommandValue("");
        setSelectedOptionIndex(0);
        setGeneratedTableNodeKeys((prevNodeKeys) => {
          const newKeys = [...prevNodeKeys]; // or any other logic to generate new keys
          // Then, pass the new keys to handleTransformNodes
          handleTransformNodes(newKeys, setLastSelection).then(() => {
            updateAiWriterMenuPosition();
          });
          // Return the new keys to update the state
          return newKeys;
        });
      });
    });
  };

  // Logic to determine if gpt_chat_stream_using_urls should be enabled
  useEffect(() => {
    if (selectedTemplate) return;
    if (!classifyChatQuery.isSuccess) return;

    const { urls, query, query_rewrites } =
      classifyChatQuery.data.classification;

    // Set a flag to determine if refetch should be called
    let shouldRefetch = false;

    if (urls.length > 0) {
      setCommandUrls(urls);
      setCommandQuery(query);
      setCommandQueryRewrites(query_rewrites);
      shouldRefetch = true;
    } else if (isSerpProcessed && isSerpContextEnabled && !isQueryNone) {
      setCommandUrls(serpUrls);
      setCommandQuery(query);
      setCommandQueryRewrites(query_rewrites);
      shouldRefetch = true;
    }

    // Use an effect to call refetch after state has been updated
    if (shouldRefetch) {
      const refetchTimer = setTimeout(() => {
        generateStreamQueryUsingUrls.refetch();
        restoreLastSelection(editor, lastSelectionRef.current);
      }, 0); // Timeout ensures refetch is called after state updates

      // Cleanup the timer when the component unmounts or the dependencies change
      return () => clearTimeout(refetchTimer);
    } else {
      const refetchTimer = setTimeout(() => {
        generateChatQuery.refetch();
        restoreLastSelection(editor, lastSelectionRef.current);
      }, 0); // Timeout ensures refetch is called after state updates

      // Cleanup the timer when the component unmounts or the dependencies change
      return () => clearTimeout(refetchTimer);
    }
  }, [
    classifyChatQuery.data,
    isSerpProcessed,
    isSerpContextEnabled,
    isQueryNone,
  ]);

  const handleContinueWriting = async ({
    shouldSetCommandValue = true,
    editorSelection = lastSelection,
  } = {}) => {
    {
      /*const closestHeadingNode = await getClosestHeadingBeforeCursor(
      editor,
      editorSelection
    );

    const newCommandValue = closestHeadingNode
      ? `Write an article section for the following heading: ${closestHeadingNode}`
      : "Continue writing";
    */
    }
    const instructionValue =
      "INSTRUCTION: Continue writing content for {CURRENT SECTION}";
    const currentSectionHeading = await getClosestHeadingBeforeCursor(
      editor,
      lastSelectionRef.current
    );
    const currentSectionHeadingValue =
      currentSectionHeading && currentSectionHeading?.length > 0
        ? "CURRENT SECTION HEADING: " + currentSectionHeading
        : "";
    const currentSectionContent = await getClosestSectionBeforeCursor(
      editor,
      lastSelectionRef.current
    );
    const currentSectionContentValue =
      currentSectionContent && currentSectionContent?.length > 0
        ? "CURRENT SECTION: " + currentSectionContent
        : "";
    const continuedWritingValue = "CONTINUED WRITING:";
    if (shouldSetCommandValue) {
      // Update the commandValue state
      const newCommandValue = [
        instructionValue,
        currentSectionHeadingValue,
        currentSectionContentValue,
        continuedWritingValue,
      ]
        .filter((text) => text.trim())
        .join("\n");
      setCommandValue(newCommandValue);

      // Also update the ref to ensure the latest value is used in generateChatQuery
      commandValueRef.current = newCommandValue;
    }

    setMaxTokens(250);

    unhighlightGeneratedContent(editor, generatedNodeKeysRef.current);
    setGeneratedNodeKeys([]);
    setIsGenerating(true);

    setTimeout(() => {
      // Now when refetching, use the current value from the ref
      generateChatQuery.refetch();
      restoreLastSelection(editor, lastSelectionRef.current);
    }, 0);
  };

  const generateRewriteQuery = useGetGptChatStream({
    assistant_seed: assistantSeed,
    previous_messages: [
      {
        role: "user",
        content: chatContext,
      },
      {
        role: "user",
        content: commandValue,
      },
    ],
    max_tokens: maxTokens,
    stop_at_newline: false,
    config: {
      enabled: false,
      staleTime: Infinity,
      retry: false,
    },
    onDataReceived: (generatedText: string) => {
      setGeneratedRewriteText((prevText) => {
        return prevText + generatedText;
      });
    },
    onDownloadComplete: () => {
      handleTrackEvent(commandValueRef.current, "gpt_chat_stream_rewrite");
      setIsGenerating(false);
      setIsGenerated(true);
      setCommandValue("");
      setSelectedOptionIndex(0);
      setGeneratedRewriteText((prevText) => {
        setRewriteTextHistory((prevHistory) => {
          if (!prevHistory.includes(prevText)) {
            const newHistory = [...prevHistory, prevText];
            setRewriteTextHistoryIndex(newHistory.length - 1);
            return newHistory;
          }
          return prevHistory;
        });
        return prevText;
      });
    },
  });

  useEffect(() => {
    if (commandValue && isRewriting && isGenerating) {
      generateRewriteQuery.refetch();
    }
  }, [commandValue, isRewriting, isGenerating]);

  const handleRewriteOptionClick = async (optionValue: string) => {
    editor.update(() => {
      let prompt = "";
      if (generatedRewriteTextRef.current.length > 0) {
        prompt = `INSTRUCTION: ${optionValue}. Keep the formatting of EDITOR CONTEXT.\n\nEDITOR CONTEXT:\n${generatedRewriteTextRef.current}\n\n REWRITTEN EDITOR CONTEXT:`;
      } else {
        const markNodes = $nodesOfType(MarkNode);
        let selectedText = "";
        markNodes.forEach((node) => {
          if (node.getIDs().includes("highlighted-content")) {
            selectedText += node.getTextContent();
          }
        });
        prompt = `INSTRUCTION: ${optionValue}.\n\nTEXT: ${selectedText}\n\n REWRITTEN TEXT:`;
      }
      if (optionValue.includes("Continue writing")) {
        setMaxTokens(250);
      }
      setGeneratedRewriteText("");
      setPreviousCommandValue(prompt);
      setCommandValue(prompt);
      setIsGenerating(true);
    });
  };

  useEffect(() => {
    if (isGenerated) {
      setTimeout(() => {
        smoothScrollIntoView(menuRef, editableRef);
      });
    }
  }, [isGenerated, isGenerating]);

  const handleOptionClick = async (optionValue: string, optionType: string) => {
    if (optionType === "template") {
      handleTemplateSelection(optionValue);
      return;
    }

    if (optionType === "rewrite") {
      handleRewriteOptionClick(optionValue);
      return;
    }

    if (
      optionValue ===
      "Write an article section for the following heading: {heading}"
    ) {
      const closestHeadingNode = await getClosestHeadingBeforeCursor(
        editor,
        lastSelection
      );

      if (closestHeadingNode) {
        setCommandValue(optionValue.replace("{heading}", closestHeadingNode));
        commandRef.current?.focus();
        return;
      }
    }

    if (optionValue === "Continue writing") {
      setPreviousIsInlineGeneratedTextNotInserted(false);
      setIsInlineGeneratedTextNotInserted(true);
      unwrapHighlightedMarkNodes(editor);
      unhighlightGeneratedContent(editor, generatedNodeKeysRef.current);
      editor.update(() => {
        const newContext = getEditorContext(lastSelectionRef.current) || [];
        setChatContext(newContext.join("\n"));
      });
      handleContinueWriting();
      return;
    }

    if (
      optionValue ===
        "Write an outline for the article. The outline should include a list of headings and sub-headings." &&
      isSerpProcessed
    ) {
      setCommandValue(optionValue);
      handleArticleOutlineSelection();
      return;
    }

    if (
      optionValue ===
        "Write a list of title ideas for the article. The title should be unique and compelling." &&
      isSerpProcessed
    ) {
      setCommandValue(optionValue);
      handleTitleIdeasSelection();
      return;
    }

    const selectedOption =
      commandOptionsRef.current[selectedOptionIndexRef.current];
    if (
      seoOptions.some((option) => option.value === optionValue) &&
      !isSerpProcessed &&
      selectedOption.type !== "submenu"
    ) {
      addNotification({
        type: "info",
        title: "Search results not processed",
        message:
          "Please enter a search query and process the search results before enabling search results.",
      });
      return;
    }

    switch (optionValue) {
      case "replace":
        replaceGeneratedText(generatedRewriteTextRef.current).then(async () => {
          resetState({
            shouldRestoreLastSelection: false,
          });
        });

        break;
      case "insert-below":
        if (selectedTemplate) {
          setIsRewriting(true);
          restoreLastSelection(editor, lastSelectionRef.current);
          handleInsertGeneratedText(
            generatedRewriteTextRef.current,
            false
          ).then(async () => {
            resetState({
              shouldRestoreLastSelection: false,
            });
          });
        } else {
          insertGeneratedTextBelow(generatedRewriteTextRef.current).then(
            async () => {
              resetState({
                shouldRestoreLastSelection: false,
              });
            }
          );
        }

        break;
      case "done":
        resetState();
        break;
      case "tryagain":
        queryClient.removeQueries(["gptChatStream"], { exact: false });
        if (selectedTemplate) {
          setIsRewriting(false);
          setIsGenerated(false);
          setShowAiWriterInput(false);
          setShowAiWriterMenu(false);
          setShowAiToolPanel(true);
          setGeneratedRewriteText("");
          setIsGenerating(false);
        } else if (isRewriting) {
          setGeneratedRewriteText("");
          setCommandValue(previousCommandValueRef.current);
          setIsGenerating(true);
        } else {
          removeGeneratedNodes().then(() => {
            updateAiWriterMenuPosition(false);
            setCommandValue(previousCommandValueRef.current);
            setIsGenerated(false);

            editor.update(() => {
              const selection = $getSelection();
              if (selection && $isRangeSelection(selection)) {
                setLastSelection(selection);
                const newContext = getEditorContext(selection) || [];
                setChatContext(newContext.join("\n"));
              }

              if (isInlineWriting) {
                resetState();
                setShowMenu(true);
                handleCommandEnter(
                  setShowMenu,
                  handleContinueWriting,
                  setIsInlineWriting,
                  setIsInlineGeneratedTextNotInserted,
                  setIsNewlineWriting,
                  setIsEmptyLine,
                  setLastSelection
                );
              }
            });
          });
        }
        break;
      case "discard":
        if (selectedTemplate) {
          resetState({
            shouldRestoreLastSelection: true,
          });
        } else if (isRewriting) {
          resetState();
        } else {
          removeGeneratedNodes().then(() => {
            resetState();
          });
        }
        break;
      case "close":
        resetState();
        break;
      default:
        if (selectedOption.type === "template") {
          setCommandValue("");
        } else {
          setCommandValue(optionValue);
        }
        commandRef.current?.focus();

        if (optionType === "write") {
          unhighlightGeneratedContent(editor, generatedNodeKeysRef.current);
          setGeneratedNodeKeys([]);
          setGeneratedTableNodeKeys(new Set<string>());
          setGeneratedNodeText([]);
          editor.update(() => {
            const newContext = getEditorContext(lastSelectionRef.current) || [];

            setChatContext(newContext.join("\n"));
          });
          setIsChatQueryEnabled(true);
          setIsGenerating(true);
        }
        break;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const handleEscapeKey = () => {
      if (isAiToolOutputLoadingRef.current === true) {
        return;
      }
      if (showSubmenuRef.current === true) {
        setShowSubmenu(false);
        event.preventDefault();
        event.stopPropagation();
      } else {
        cancelGptChatStream();
        cancelGptChatStreamUsingUrls();
        queryClient.cancelQueries(["gptChatStream"], { exact: false });
        resetState();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (isGeneratingRef.current === true) {
      if (event.key === "Escape") {
        handleEscapeKey();
      } else {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
    const commandValue = event.target.value;

    const handleArrowUpKey = () => {
      if (showSubmenuRef.current === true) {
        const options =
          submenuOptions.length > 0 ? submenuOptions : recentPromptOptions;
        setSubMenuSelectedOptionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : options.length - 1
        );
      } else {
        setSelectedOptionIndex((prevIndex) => {
          let newIndex = prevIndex;
          do {
            newIndex =
              newIndex === 0
                ? commandOptionsRef.current.length - 1
                : newIndex - 1;
          } while (
            commandOptionsRef.current[newIndex] &&
            commandOptionsRef.current[newIndex].value === "label"
          );
          return newIndex;
        });
      }

      event.preventDefault();
      event.stopPropagation();
    };

    const handleArrowDownKey = () => {
      if (showSubmenuRef.current === true) {
        const options =
          submenuOptions.length > 0 ? submenuOptions : recentPromptOptions;
        setSubMenuSelectedOptionIndex((prevIndex) =>
          prevIndex < options.length - 1 ? prevIndex + 1 : 0
        );
      } else {
        setSelectedOptionIndex((prevIndex) => {
          let newIndex = prevIndex;
          do {
            newIndex =
              newIndex === commandOptionsRef.current.length - 1
                ? 0
                : newIndex + 1;
          } while (
            commandOptionsRef.current[newIndex] &&
            commandOptionsRef.current[newIndex].value === "label"
          );
          return newIndex;
        });
      }

      event.preventDefault();
      event.stopPropagation();
    };

    const handleEnterKey = () => {
      if (event.key === "Enter" && event.shiftKey === true) {
        setCommandValue((prevValue) => prevValue + "\n");
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (!showMenu || isGeneratingRef.current === true) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      const selectedOption =
        commandOptionsRef.current[selectedOptionIndexRef.current];
      const selectedSubMenuOption =
        selectedOption &&
        selectedOption.submenu &&
        selectedOption.submenu.length > 0 &&
        selectedOption.submenu[subMenuSelectedOptionIndexRef.current];

      if (showSubmenuRef.current === true && selectedSubMenuOption) {
        setShowSubmenu(false);
        if (selectedSubMenuOption.type === "rewrite") {
          handleRewriteOptionClick(selectedSubMenuOption.value);
        } else if (selectedSubMenuOption.type === "template") {
          handleTemplateSelection(selectedSubMenuOption.value);
        } else {
          setCommandValue(selectedSubMenuOption.value);
        }
      } else if (selectedOption && selectedOption.type === "submenu") {
        setSubmenuOptions(selectedOption.submenu);
        setSubMenuSelectedOptionIndex(0);
        setShowSubmenu(true);
      } else if (isRewritingRef.current && commandValueRef.current.length > 0) {
        handleRewriteOptionClick(commandValueRef.current);
      } else if (
        showMenu === true &&
        isGeneratedRef.current === false &&
        commandValueRef.current.length > 0 &&
        selectedOption
      ) {
        if (commandValueRef.current === selectedOption.value) {
          editor.update(() => {
            const newContext = getEditorContext(lastSelectionRef.current) || [];

            setChatContext(newContext.join("\n"));
          });
          setIsChatQueryEnabled(true);
          setIsGenerating(true);
        } else if (selectedOption.type === "template") {
          handleTemplateSelection(selectedOption.value);
        } else {
          setCommandValue(selectedOption.value);
        }
      } else if (
        showMenu === true &&
        isGeneratedRef.current === false &&
        commandValueRef.current.length > 0
      ) {
        editor.update(() => {
          const newContext = getEditorContext(lastSelectionRef.current) || [];

          setChatContext(newContext.join("\n"));
        });
        setIsChatQueryEnabled(true);
        setIsGenerating(true);
      } else if (selectedOption) {
        handleOptionClick(selectedOption.value, selectedOption.type || "");
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const handleBackspaceKey = () => {
      if (
        showMenu === true &&
        (commandValue === "" || commandValue === undefined)
      ) {
        resetState();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    switch (event.key) {
      case "ArrowUp":
        if (showAiToolPanelRef.current) {
          return;
        } else if (!editorFocusedRef.current && showMenuRef.current) {
          handleArrowUpKey();
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case "ArrowDown":
        if (!editorFocusedRef.current && showMenuRef.current) {
          handleArrowDownKey();
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case "Enter":
        if (showAiToolPanelRef.current) {
          return;
        } else if (
          !isGeneratingRef.current &&
          !editorFocusedRef.current &&
          showMenuRef.current
        ) {
          handleEnterKey();
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case "Escape":
        handleEscapeKey();
        break;
      case "Backspace":
        if (
          !editorFocusedRef.current &&
          (commandValue === "" || commandValue === undefined) &&
          showMenuRef.current
        ) {
          handleBackspaceKey(); // Only call when there is no command value
        }
        break;
      default:
        break;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    // If an AI Tool is open, prevent the default behavior and stop propagation.
    if (showAiToolPanelRef.current) {
      return;
    }

    // If the AI is currently generating content, prevent the default behavior and stop propagation.
    if (
      isGeneratingRef.current ||
      classifyChatQuery.isLoading ||
      generateChatQuery.isLoading ||
      generateStreamQueryUsingUrls.isLoading
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
    // If the AI is not generating and the click is outside the input and menu elements, reset the state.
    else if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      (!subMenuRef.current ||
        !subMenuRef.current.contains(event.target as Node))
    ) {
      resetState();
    }
  };

  useEffect(() => {
    if (subMenuRef.current && menuRef.current) {
      setSubmenuPosition(subMenuRef.current, menuRef.current);
    }
  }, [showSubmenu]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  {
    /*useEffect(() => {
    if (inputRef.current && linePlaceholderRef.current) {
      setMenuPosition(inputRef.current, anchorElem, linePlaceholderRef.current);
    } else {
      editor.update(() => {
        const nodes = $getSelection()?.getNodes();
        if (nodes?.length > 0) {
          const selectionKey = nodes[0].getKey();
          const selectionElem = editor.getElementByKey(selectionKey);
          if (selectionElem) {
            const selectionRect = selectionElem.getBoundingClientRect();
            const inputRect = inputRef.current?.getBoundingClientRect();
            const anchorRect = anchorElem?.getBoundingClientRect();

            if (inputRef.current && selectionRect && inputRect) {
              // I want to dock the input to the bottom of the selection
              const topPosition = selectionRect.bottom - anchorRect.top - 16;
              inputRef.current.style.transform = `translate(24px, ${topPosition}px)`;
              inputRef.current.style.width = `750px`;
            }
          }
        }
      });
    }
    commandRef.current?.focus();
  }, [inputRef.current, linePlaceholderRef.current]);*/
  }

  useEffect(() => {
    if (showAiToolPanelRef.current) {
      return;
    }

    if (showMenu && linePlaceholderRef.current && inputRef.current) {
      if (isGenerating && generatedNodeKeys.length > 0) {
        setGeneratingMenuPosition(
          inputRef.current,
          anchorElem,
          linePlaceholderRef.current
        );

        commandRef.current?.focus();
      } else {
        setMenuPosition(
          inputRef.current,
          anchorElem,
          linePlaceholderRef.current
        );
        commandRef.current?.focus();
      }
    } else {
      editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          const nodes = selection.getNodes();
          if (nodes.length > 0) {
            const selectionKey = nodes[0].getKey();
            const selectionElem = editor.getElementByKey(selectionKey);
            if (selectionElem) {
              const selectionRect = selectionElem.getBoundingClientRect();
              const inputRect = inputRef.current?.getBoundingClientRect();
              const anchorRect = anchorElem?.getBoundingClientRect();

              if (inputRef.current && selectionRect && inputRect) {
                const topPosition = selectionRect.bottom - anchorRect.top - 16;
                if (generatedNodeKeysRef.current.length > 0) {
                  inputRef.current.style.transition = "transform 0.2s";
                }
                inputRef.current.style.transform = `translate(24px, ${topPosition}px)`;
                inputRef.current.style.width = `100%`;
              }
            }
          }
        }
      });
      setTimeout(() => {
        commandRef.current?.focus();
      }, 0);
    }
  }, [
    showMenu,
    editor,
    isGenerating,
    isGenerated,
    lastSelection,
    lastSelectionRef.current,
    inputRef.current,
    isRewriting,
  ]);

  {
    /*useEffect(() => {
    if (
      previousIsInlineGeneratedTextNotInserted &&
      !isInlineGeneratedTextNotInserted
    ) {
      editor.update(() => {
        const nodes = lastSelection?.getNodes();
        if (nodes?.length > 0) {
          const selectionKey = nodes[0].getKey();
          const selectionElem = editor.getElementByKey(selectionKey);
          if (selectionElem) {
            const selectionRect = selectionElem.getBoundingClientRect();
            const inputRect = inputRef.current?.getBoundingClientRect();
            const anchorRect = anchorElem?.getBoundingClientRect();

            if (inputRef.current && selectionRect && inputRect) {
              // I want to dock the input to the bottom of the selection
              const topPosition = selectionRect.bottom - anchorRect.top - 16;
              inputRef.current.style.transition = `transform 0.1s`;
              inputRef.current.style.transform = `translate(24px, ${topPosition}px)`;
              inputRef.current.style.width = `750px`;
            }
          }
        }
      });
    }
    // Update the previous value for the next render
    setPreviousIsInlineGeneratedTextNotInserted(
      isInlineGeneratedTextNotInserted
    );
  }, [isInlineGeneratedTextNotInserted, editor, lastSelection, inputRef]);*/
  }

  const getEditorContext = (newSelection: RangeSelection | null) => {
    const title = document.getElementById("title-input")?.innerHTML;
    const query = fraseDocument.query || "";
    let contextParts = [];

    if (query) {
      contextParts.push(`QUERY: ${query}`);
    }

    if (title && title !== "Untitled") {
      contextParts.push(`TITLE: ${title}`);
    }

    let markdownString = $convertToMarkdownString(
      PLAYGROUND_TRANSFORMERS,
      $getRoot()
    );
    let selection = newSelection?.clone() || $getSelection();

    const anchorNodeKey = selection?.focus?.key;
    const anchorNode = $getNodeByKey(anchorNodeKey);
    if (!anchorNode) {
      selection = $getSelection() as RangeSelection;
    }

    if (selection && $isRangeSelection(selection)) {
      const selectionNode = selection.getNodes()[0];
      let selectionTextBeforeCursor = selectionNode
        .getTextContent()
        .slice(0, selection.anchor.offset)
        .replace(/\n/g, "")
        .trim();

      if (selectionTextBeforeCursor.length === 0) {
        let selectionAnchorNode = selection.anchor.getNode();

        if ($isRootNode(selectionAnchorNode)) {
          return contextParts; // Return the context parts collected so far
        } else {
          selectionAnchorNode = selectionAnchorNode.getTopLevelElementOrThrow();
        }

        while (selectionAnchorNode && selectionTextBeforeCursor.length === 0) {
          selectionAnchorNode = selectionAnchorNode.getPreviousSibling();
          if (selectionAnchorNode) {
            selectionTextBeforeCursor = selectionAnchorNode
              .getTextContent()
              .replace(/\n/g, "");
          }
        }

        if (!selectionAnchorNode) {
          return contextParts; // Return the context parts collected so far
        }
      }

      // Find the markdown content up to the selectionTextBeforeCursor
      const markdownContentUpToCursor = markdownString.substring(
        0,
        markdownString.indexOf(selectionTextBeforeCursor) +
          selectionTextBeforeCursor.length
      );

      const lastCharacter = markdownContentUpToCursor.trim().slice(-1);
      const punctuation = [".", "!", "?"];
      let inlineWriting = false;

      const parentNode = selection.anchor.getNode();
      if ($isParagraphNode(parentNode) && parentNode.isEmpty()) {
        inlineWriting = false;
      } else {
        inlineWriting = true;
      }

      if (inlineWriting && !punctuation.includes(lastCharacter)) {
        setShouldStopAtNewLine(true);
      } else {
        setShouldStopAtNewLine(false);
      }

      // Push the markdown content to contextParts
      contextParts.push(
        `ARTICLE:\n\n${markdownContentUpToCursor.slice(-2000)}`
      );
    }

    return contextParts;
  };

  useCommandSubscription(
    KEY_DOWN_COMMAND,
    (event: KeyboardEvent | null) => {
      if (event && isGeneratingRef.current) {
        if (event.key === "Escape") {
          if (showSubmenuRef.current === true) {
            setShowSubmenu(false);
            event.preventDefault();
            event.stopPropagation();
          } else {
            cancelGptChatStream();
            cancelGptChatStreamUsingUrls();
            queryClient.cancelQueries(["gptChatStream"], { exact: false });
            resetState();
          }
        }
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL
  );

  useCommandSubscription(
    SELECTION_CHANGE_COMMAND,
    () => {
      const selection = $getSelection();
      if (showAiToolPanelRef.current) {
        return false;
      }

      if (selection && $isRangeSelection(selection)) {
        setLastSelection(selection);
        const newContext = getEditorContext(selection) || [];
        setChatContext(newContext.join("\n"));
        return true;
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL
  );

  useCommandSubscription(
    KEY_ENTER_COMMAND,
    (event: KeyboardEvent | null) => {
      if (event?.key === "Enter" && (event.metaKey || event.ctrlKey)) {
        handleCommandEnter(
          setShowMenu,
          handleContinueWriting,
          setIsInlineWriting,
          setIsInlineGeneratedTextNotInserted,
          setIsNewlineWriting,
          setIsEmptyLine,
          setLastSelection
        );

        event.preventDefault();
        event.stopImmediatePropagation();
        return true;
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL
  );

  useCommandSubscription(
    KEY_SPACE_COMMAND,
    (event: KeyboardEvent | null) => {
      let shouldConsumeEvent = false;
      editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          const parentNode = selection.anchor.getNode();
          if (
            $isParagraphNode(parentNode) &&
            parentNode.isEmpty() &&
            !$isTableCellNode(parentNode.getParent()) &&
            !$isTableRowNode(parentNode.getParent())
          ) {
            setShowMenu(true);
            setSelectedOptionIndex(1);
            shouldConsumeEvent = true;
            event?.preventDefault();
            event?.stopImmediatePropagation();

            setInitialSelection(selection);
            setLastSelection(selection);
            setIsNewlineWriting(true);
            setIsEmptyLine(true);
          }
        }
      });

      if (shouldConsumeEvent && event !== null) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      return shouldConsumeEvent;
    },
    COMMAND_PRIORITY_CRITICAL
  );

  // Trigger Rewrite workflow
  useCommandSubscription(
    KEY_DOWN_COMMAND,
    (event: KeyboardEvent | null) => {
      if (event && (event.ctrlKey || event.metaKey) && event.key === "j") {
        editor.update(
          () => {
            const selection = $getSelection();
            const isBackward = selection?.isBackward() || false;

            if ($isRangeSelection(selection)) {
              $wrapSelectionInMarkNode(
                selection,
                isBackward,
                "highlighted-content"
              );
            }

            const markNodes = $nodesOfType(MarkNode);
            setLastSelection(markNodes[markNodes.length - 1].select());
          },
          {
            tag: "history-merge",
          }
        );

        setTimeout(() => {
          setShowMenu(true);
          setIsRewriting(true);
        }, 0);
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const menuElement = menuRef.current;
    const inputElement = inputRef.current;

    if (menuElement) {
      menuElement.addEventListener("keydown", handleKeyDown);
    }
    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (menuElement) {
        menuElement.removeEventListener("keydown", handleKeyDown);
      }
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [menuRef.current, inputRef.current]);

  useEffect(() => {
    if (
      generateChatQuery.isError ||
      generateStreamQueryUsingUrls.isError ||
      classifyChatQuery.isError
    ) {
      resetState();
    }
  }, [
    generateChatQuery.isError,
    generateStreamQueryUsingUrls.isError,
    classifyChatQuery.isError,
  ]);

  useEffect(() => {
    if (rewriteTextRef.current) {
      setTimeout(() => {
        rewriteTextRef.current.scrollTop = rewriteTextRef.current.scrollHeight;
      }, 0);
    }
  }, [generatedRewriteText]);

  /*useEffect(() => {
    // scroll into view inputRef
    if (inputRef.current) {
      inputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    if (menuRef.current) {
      menuRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    if (isRewriting) {
      setShowAiToolPanel(false);
      setShowAiWriterInput(true);
      setShowAiWriterMenu(true);
    }
  }, [showMenu, isRewriting, isGenerated, isGenerating]);*/

  const handleScrollIntoView = () => {
    if (menuRef.current && inputRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const inputRect = inputRef.current.getBoundingClientRect();
      const isVisible = (rect) => {
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
      };

      if (!isVisible(menuRect) || !isVisible(inputRect)) {
        setTimeout(() => {
          inputRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          menuRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
          commandRef.current?.focus();
        }, 100);
      }
    }
  };

  /*useEffect(() => {
    if (isRewriting || showMenu) {
      handleScrollIntoView();
    }
  }, [isRewriting, showMenu]);*/

  useEffect(() => {
    if (menuRef.current) {
      const menuElement = menuRef.current;
      const selectedOptionElement = menuElement.querySelector(
        `li:nth-child(${selectedOptionIndex + 1})`
      );

      if (selectedOptionElement) {
        if (selectedOptionIndex === 1) {
          menuElement.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else if (
          selectedOptionIndex ===
          commandOptionsRef.current.length - 1
        ) {
          menuElement.scrollTo({
            top: menuElement.scrollHeight,
            behavior: "smooth",
          });
        } else {
          selectedOptionElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }
  }, [selectedOptionIndex, commandOptionsRef]);

  function getPlaceholderText() {
    if (isRewriting) {
      if (selectedTemplate) {
        return "Tell AI to refine the generated text...";
      } else if (generatedRewriteText.length > 0) {
        return "Tell AI to refine the rewritten text...";
      } else {
        return "Tell AI what to edit or generate...";
      }
    } else if (isGenerated) {
      return "Tell AI what to do next...";
    } else {
      return "Ask AI to write anything...";
    }
  }

  const smoothScrollIntoView = (
    ref: React.RefObject<HTMLDivElement>,
    editableRef: React.RefObject<HTMLDivElement>,
    padding = 20
  ) => {
    const rect = ref.current.getBoundingClientRect();
    const editableRect = editableRef.current.getBoundingClientRect();

    const topRelativeToEditable = rect.top - editableRect.top;
    const bottomRelativeToEditable = rect.bottom - editableRect.top;

    const scrollEditable = (targetScrollTop: number) => {
      const startScrollTop = editableRef.current.scrollTop;
      const distance = targetScrollTop - startScrollTop;
      const duration = 200; // duration in ms
      let startTime: number | null = null;

      const animateScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easeInOutQuad = (t: number) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const easedProgress = easeInOutQuad(progress);

        editableRef.current.scrollTop =
          startScrollTop + distance * easedProgress;

        if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    };

    if (
      bottomRelativeToEditable > editableRect.height ||
      topRelativeToEditable < 0
    ) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });

      if (bottomRelativeToEditable > editableRect.height) {
        scrollEditable(
          editableRef.current.scrollTop +
            bottomRelativeToEditable -
            editableRect.height +
            padding
        );
      } else if (topRelativeToEditable < 0) {
        scrollEditable(
          editableRef.current.scrollTop + topRelativeToEditable - padding
        );
      }
    } else {
      if (bottomRelativeToEditable > editableRect.height) {
        scrollEditable(
          editableRef.current.scrollTop +
            bottomRelativeToEditable -
            editableRect.height +
            padding
        );
      } else if (topRelativeToEditable < 0) {
        scrollEditable(
          editableRef.current.scrollTop + topRelativeToEditable - padding
        );
      }
    }
  };

  useEffect(() => {
    if (showMenu) {
      refetchBookmarkedTemplates();
    }
  }, [showMenu, refetchBookmarkedTemplates]);

  useLayoutEffect(() => {
    if (
      showMenu &&
      inputRef.current &&
      editableRef.current &&
      menuRef.current
    ) {
      setTimeout(() => {
        smoothScrollIntoView(menuRef, editableRef);
      }, 0);
    }
  }, [showMenu]);

  useEffect(() => {
    const fetchLastHeadingAndSection = async () => {
      const lastHeadingBeforeSelection = await getClosestHeadingBeforeCursor(
        editor,
        lastSelectionRef.current
      );
      const lastSectionBeforeSelection = await getClosestSectionBeforeCursor(
        editor,
        lastSelectionRef.current
      );

      setLastHeadingBeforeSelection(lastHeadingBeforeSelection);
      setLastSectionBeforeSelection(lastSectionBeforeSelection);
    };

    fetchLastHeadingAndSection();
  }, [showAiToolPanelRef.current]);

  return showMenu
    ? createPortal(
        <div
          ref={inputRef}
          className="absolute top-0 left-0 mt-[22px] z-[50]"
          id="ai-command-input-container"
        >
          <div
            className={cn(
              "group z-50 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg inset-0 ring-1 group ring-zinc-900/7.5 group-focus:ring-zinc-900/20 dark:ring-zinc-700",
              showAiToolPanel && "bg-transparent w-1/2 min-w-[400px] rounded-md"
            )}
          >
            {showAiWriterInput && (
              <div className="flex-col w-full">
                <div className="flex items-center w-full">
                  <div className="flex flex-col items-start w-full">
                    {isRewriting &&
                      generatedRewriteText &&
                      generatedRewriteText.length > 0 && (
                        <TextareaAutosize
                          id="ai-command-input"
                          maxLength={2000}
                          rows={1}
                          style={{
                            width: "100%",
                          }}
                          readOnly
                          ref={rewriteTextRef}
                          className="resize-none text-sm max-h-80 overflow-y-auto font-[450] mt-[4px] px-3 text-zinc-900 dark:text-white bg-transparent dark:bg-zinc-800 focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none focus:ring-transparent focus:ring-inset  placeholder:text-zinc-400 leading-[21px]"
                          value={generatedRewriteText}
                          placeholder={getPlaceholderText()}
                          onFocus={() => {
                            setCommandFocused(true);
                          }}
                        />
                      )}

                    {generateChatQuery.isLoading ||
                    generateStreamQueryUsingUrls.isLoading ||
                    classifyChatQuery.isLoading ||
                    isGenerating ? (
                      <div
                        className={cn(
                          "flex h-10 bg-white dark:bg-zinc-800 font-medium w-full items-center justify-between rounded-tl-md rounded-tr-md px-3",
                          isRewriting && "rounded-b-md"
                        )}
                      >
                        <div className="flex items-center">
                          <TbSparkles
                            className="text-emerald-600 w-4 h-4 mr-2"
                            style={{
                              fill: "#059669",
                              strokeColor: "#059669",
                            }}
                          />
                          <p
                            className="transition-all duration-500 font-medium text-emerald-600 text-sm"
                            id="ai-command-input-loading-text"
                          >
                            {classifyChatQuery.isLoading
                              ? "Thinking..."
                              : generateStreamQueryUsingUrls.isLoading
                              ? commandUrls.length === 1
                                ? "Writing using url content..."
                                : "Writing using web results..."
                              : isRewriting
                              ? "Rewriting using editor context..."
                              : "Writing using editor context..."}
                          </p>
                          <Spinner size="sm" className="ml-3" />
                        </div>
                        <div className="flex items-center">
                          <p className="font-medium text-xs text-zinc-400">
                            Stop
                          </p>
                          <ShortcutTag className="opacity-70">Esc</ShortcutTag>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-start w-full p-1.5">
                        <TbSparkles
                          style={{
                            fill: "#059669",
                            strokeColor: "#059669",
                          }}
                          className="text-emerald-600 w-4.5 h-4.5 flex-shrink-0 mx-1 mt-[6px]"
                        />
                        <TextareaAutosize
                          id="ai-command-input"
                          maxLength={2000}
                          rows={1}
                          style={{
                            width: "100%",
                          }}
                          onChange={(event) => {
                            if (isGenerated) {
                              setIsGenerated(false);
                            }
                            setCommandValue(event.target.value);
                          }}
                          ref={commandRef}
                          className="resize-none text-sm font-[450] mt-[4px] pl-1 pr-3 pt-0 pb-0 text-zinc-900 dark:text-white bg-transparent dark:bg-zinc-800 focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none focus:ring-transparent focus:ring-inset  placeholder:text-zinc-400 leading-[21px]"
                          value={commandValue}
                          placeholder={getPlaceholderText()}
                          onFocus={() => {
                            setCommandFocused(true);
                          }}
                        />
                        <div>
                          <Button
                            variant="buttonNodeIcon"
                            id="ai-command-input-button"
                            size="lg"
                            buttonIcon={<TbCircleArrowRightFilled />}
                            className={cn(
                              "text-zinc-400 dark:text-zinc-600",
                              (commandValue?.trim().length > 0 ||
                                String(
                                  document.getElementById("ai-command-input")
                                    ?.innerHTML || ""
                                ).trim().length > 0) &&
                                "text-emerald-600 dark:text-emerald-600"
                            )}
                            disabled={commandValue.trim().length === 0}
                            onClick={(event) => {
                              event.preventDefault(); // Prevent the default form submit behavior
                              const inputElement =
                                document.getElementById("ai-command-input");
                              const inputValue = inputElement?.innerHTML.trim();
                              if (inputValue && inputValue.length > 0) {
                                setCommandValue(inputValue); // Set commandValue to the innerHTML of the input element if it exists and is not empty
                                editor.update(() => {
                                  const newContext =
                                    getEditorContext(
                                      lastSelectionRef.current
                                    ) || [];
                                  setChatContext(newContext.join("\n"));
                                });
                                setIsChatQueryEnabled(true);
                                setIsGenerating(true);
                              }
                            }}
                            tooltipContent="Write with AI"
                            tooltipModifierKey="Enter"
                          />
                        </div>
                        {isRewriting && rewriteTextHistory.length > 1 && (
                          <RewriteHistoryNavigator
                            rewriteTextHistory={rewriteTextHistory}
                            rewriteTextHistoryIndex={rewriteTextHistoryIndex}
                            setRewriteTextHistoryIndex={
                              setRewriteTextHistoryIndex
                            }
                            setGeneratedRewriteText={setGeneratedRewriteText}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!isRewriting && (
                  <div className="py-1 pl-2 bg-zinc-50 dark:bg-zinc-900/50 border-t group border-zinc-900/7.5 group-focus:border-zinc-900/20 dark:border-zinc-700 rounded-b-md">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="pl-1 space-x-2 flex items-center">
                            <span className="text-2xs text-zinc-900/40 dark:text-zinc-100/40 font-[450]">
                              Use search results
                            </span>
                            <Switch
                              size="xs"
                              checked={isSerpProcessed && isSerpContextEnabled}
                              disabled={!isSerpProcessed}
                              onCheckedChange={(checked) => {
                                if (!isSerpProcessed) {
                                  addNotification({
                                    type: "info",
                                    title: "Search results not processed",
                                    message:
                                      "Please enter a search query and process the search results from the right sidebar before enabling search results.",
                                  });
                                }

                                setIsSerpContextEnabled(checked);
                              }}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent
                          side="bottom"
                          align="start"
                          hidden={isSerpProcessed}
                        >
                          Please enter a search query and process the search
                          results before enabling search results.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </div>
            )}
            {showAiWriterMenu && (
              <div
                ref={menuRef}
                className={cn(
                  "absolute top-0 px-1 left-0 mt-1 min-w-80 w-80 group py-1 overflow-hidden z-50 origin-top-right divide-y divide-zinc-100 dark:divide-zinc-600 rounded-md bg-white dark:bg-zinc-800 shadow-lg dark:shadow-xl ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-700 ring-opacity-5 focus:outline-none",
                  (commandOptions.length === 0 || isGenerating) && "hidden",
                  commandOptions?.length > 10
                    ? "max-h-80 overflow-y-scroll pr-0.5"
                    : "",
                  inputRef?.current &&
                    `top-[${inputRef.current.getBoundingClientRect().bottom}px]`
                )}
              >
                <ul
                  key={commandOptions.length}
                  className="overflow-hidden  text-zinc-700 dark:text-zinc-400 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:pb-1.5 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-semibold [&_[cmdk-group-heading]]:text-zinc-900 [&_[cmdk-group-heading]]:dark:text-zinc-300 focus:[&:not(:focus-visible)]:outline-none focus:outline-none focus:ring-transparent aria-selected:ring-transparent aria-selected:outline-none"
                >
                  {commandOptions.map((option, index) =>
                    option.value === "label" ? (
                      <li
                        key={index}
                        className={cn(
                          "px-2 first:pt-1 pt-2 pb-1 font-medium text-2xs text-zinc-500 dark:text-zinc-500 border-t dark:border-t-zinc-700 first:border-none",
                          option.label.length === 0 && "pb-0 pt-1"
                        )}
                      >
                        {option.label}
                      </li>
                    ) : option.type === "submenu" ? (
                      <li
                        key={index}
                        className={cn(
                          "justify-between text-xs cursor-pointer font-medium select-none aria-selected:bg-zinc-100 dark:aria-selected:bg-zinc-700 group flex w-full items-center rounded-md px-2 py-1 text-zinc-500 focus:outline-none outline-none focus:ring-transparent border-transparent focus:border-transparent focus:ring-0 mb-1 last:mb-0",
                          index === selectedOptionIndex
                            ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                            : ""
                        )}
                        onMouseOver={(event) => {
                          if (event.target === event.currentTarget) {
                            setSelectedOptionIndex(index);
                            setSubmenuOptions(option.submenu);
                            setShowSubmenu(true);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            setSubmenuOptions(option.submenu);
                            setShowSubmenu(true);
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        onMouseEnter={() => {
                          // Clear any existing timeout to ensure the submenu stays open while the mouse is over it
                          if (subMenuItemsRef.current.timeoutId) {
                            clearTimeout(subMenuItemsRef.current.timeoutId);
                          }
                        }}
                        onMouseLeave={() => {
                          // Start a timeout to hide the submenu when the mouse leaves the component
                          subMenuItemsRef.current.timeoutId = setTimeout(() => {
                            setShowSubmenu(false);
                          }, 500); // Adjust the delay as needed
                        }}
                        onClick={() => {
                          setShowSubmenu(true);
                        }}
                      >
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center">
                            {React.cloneElement(option.icon, {
                              className: cn(
                                "w-4 h-4",
                                option.icon.props.className
                              ),
                            })}
                            <span className="ml-2">{option.label}</span>
                          </div>
                          <TbChevronRight className="w-3 h-3 ml-1.5 text-zinc-400 dark:text-zinc-500" />
                        </div>
                        {/* Submenu toggle icon can be added here */}
                      </li>
                    ) : (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <li
                              key={index}
                              className={cn(
                                "justify-between text-xs cursor-pointer font-[450] select-none aria-selected:bg-zinc-100 dark:aria-selected:bg-zinc-700 group flex w-full items-center rounded-md px-2 py-0.5 text-zinc-500 focus:outline-none outline-none focus:ring-transparent border-transparent focus:border-transparent focus:ring-0",
                                index === selectedOptionIndex
                                  ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                                  : "",
                                commandOptions[index + 1] &&
                                  commandOptions[index + 1].value === "label" &&
                                  "mb-1",
                                seoOptions.includes(option) &&
                                  !isSerpProcessed &&
                                  "opacity-50 cursor-not-allowed"
                              )}
                              onMouseOver={() => {
                                setSelectedOptionIndex(index);
                              }}
                              onClick={() => {
                                handleOptionClick(
                                  option.value,
                                  option.type || ""
                                );
                              }}
                            >
                              <div className="flex items-center w-full">
                                {option.icon}
                                <span className="ml-2 truncate">
                                  {option.label}
                                </span>
                              </div>
                              {option.shortcut && (
                                <ShortcutTag className="ml-2 opacity-70">
                                  {option.shortcut}
                                </ShortcutTag>
                              )}
                            </li>
                          </TooltipTrigger>
                          <TooltipContent
                            side="bottom"
                            align="start"
                            hidden={
                              !(seoOptions.includes(option) && !isSerpProcessed)
                            }
                          >
                            Please enter a search query and process the search
                            results before enabling search results.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )
                  )}
                </ul>
              </div>
            )}
            {showAiToolPanel && (
              <AiToolPanel
                previousSection={lastSectionBeforeSelection}
                previousHeading={lastHeadingBeforeSelection}
                query={fraseDocument.query}
                documentTitle={title}
                fraseDocument={fraseDocument}
                template={selectedTemplate}
                templateFieldsData={templatesFieldsData}
                setTemplateFieldsData={setTemplatesFieldsData}
                isAiToolOutputLoading={isAiToolOutputLoading}
                setIsAiToolOutputLoading={setIsAiToolOutputLoading}
                refetchTemplates={refetchBookmarkedTemplates}
                onBack={() => {
                  setShowAiToolPanel(false);
                  setShowAiWriterInput(true);
                  setShowAiWriterMenu(true);
                }}
                onClose={() => {
                  resetState();
                }}
                onGenerateComplete={(generatedText: string) => {
                  restoreLastSelection(editor, lastSelectionRef.current);
                  setShowAiToolPanel(false);
                  setShowAiWriterInput(true);
                  setShowAiWriterMenu(true);
                  setIsGenerating(false);
                  setIsGenerated(true);
                  setCommandValue("");
                  setSelectedOptionIndex(0);
                  setIsRewriting(true);
                  setGeneratedRewriteText(generatedText);
                  setRewriteTextHistory((prevHistory) => {
                    if (!prevHistory.includes(generatedText)) {
                      const newHistory = [...prevHistory, generatedText];
                      setRewriteTextHistoryIndex(newHistory.length - 1);
                      return newHistory;
                    }
                    return prevHistory;
                  });
                }}
              />
            )}
            {showSubmenu && (
              <div
                onMouseEnter={() => {
                  // Clear any existing timeout to ensure the submenu stays open while the mouse is over it
                  if (subMenuItemsRef.current.timeoutId) {
                    clearTimeout(subMenuItemsRef.current.timeoutId);
                  }
                }}
                ref={subMenuRef}
                onMouseLeave={() => {
                  // Start a timeout to hide the submenu when the mouse leaves the component
                  subMenuItemsRef.current.timeoutId = setTimeout(() => {
                    setShowSubmenu(false);
                  }, 500); // Adjust the delay as needed
                }}
                className={cn(
                  "absolute min-w-80 w-80 group py-1 overflow-hidden z-50 origin-top-right divide-y divide-zinc-100 dark:divide-zinc-600 rounded-md bg-white dark:bg-zinc-800 shadow-lg dark:shadow-xl ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-700 ring-opacity-5 focus:outline-none",
                  (commandOptions.length === 0 || isGenerating) && "hidden"
                )}
              >
                <ul
                  className={cn(
                    "overflow-x-hidden ml-1 pr-1 text-zinc-700 dark:text-zinc-400 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:pb-1.5 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-semibold [&_[cmdk-group-heading]]:text-zinc-900 [&_[cmdk-group-heading]]:dark:text-zinc-300 focus:[&:not(:focus-visible)]:outline-none focus:outline-none focus:ring-transparent aria-selected:ring-transparent aria-selected:outline-none",
                    (submenuOptions.length > 0
                      ? submenuOptions
                      : recentPromptOptions
                    ).length > 6
                      ? "max-h-60 overflow-y-auto pr-0.5"
                      : "max-h-40 overflow-y-auto pr-1"
                  )}
                >
                  {(submenuOptions.length > 0
                    ? submenuOptions
                    : recentPromptOptions
                  ).map((option, index) => (
                    <li
                      key={index}
                      ref={(el) => {
                        if (el) {
                          subMenuItemsRef.current[index] = el;
                        }
                      }}
                      className={cn(
                        "justify-between text-xs cursor-pointer font-[450] select-none aria-selected:bg-zinc-100 dark:aria-selected:bg-zinc-700 group flex w-full items-center rounded-md px-2 py-1 text-zinc-500 focus:outline-none outline-none focus:ring-transparent border-transparent focus:border-transparent focus:ring-0",
                        index === subMenuSelectedOptionIndex
                          ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                          : "",
                        commandOptions[index + 1] &&
                          commandOptions[index + 1].value === "label" &&
                          "mb-1",
                        seoOptions.includes(option) &&
                          !isSerpProcessed &&
                          "opacity-50 pointer-events-none"
                      )}
                      onClick={() => {
                        if (isRewriting) {
                          handleOptionClick(option.value, option.type || "");
                        } else if (option.type === "template") {
                          handleTemplateSelection(option.value);
                          setShowAiWriterInput(false);
                          setShowAiWriterMenu(false);
                          setShowSubmenu(false);
                        } else {
                          setCommandValue(option.value);
                        }
                      }}
                      onMouseOver={() => {
                        setSubMenuSelectedOptionIndex(index);
                      }}
                    >
                      <div className="flex items-center w-full">
                        {option.icon}
                        <span className="ml-2 truncate">{option.label}</span>
                      </div>
                      {option.shortcut && (
                        <ShortcutTag className="ml-2 opacity-70">
                          {option.shortcut}
                        </ShortcutTag>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>,
        anchorElem
      )
    : null;
}

function handleCommandEnter(
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
  handleContinueWriting: ({
    shouldSetCommandValue,
    editorSelection,
  }?: {
    shouldSetCommandValue?: boolean | undefined;
    editorSelection?: RangeSelection | null | undefined;
  }) => Promise<void>,
  setIsInlineWriting: React.Dispatch<React.SetStateAction<boolean>>,
  setIsInlineGeneratedTextNotInserted: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setIsNewlineWriting: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEmptyLine: React.Dispatch<React.SetStateAction<boolean>>,
  setLastSelection: React.Dispatch<React.SetStateAction<RangeSelection | null>>
) {
  const selection = $getSelection();
  if (selection && $isRangeSelection(selection)) {
    const parentNode = selection.anchor.getNode();
    setLastSelection(selection);
    if ($isParagraphNode(parentNode) && parentNode.isEmpty()) {
      setIsNewlineWriting(true);
      setIsEmptyLine(true);
      setShowMenu(true);
      handleContinueWriting({
        shouldSetCommandValue: true,
        editorSelection: selection,
      });
    } else {
      setIsInlineWriting(true);
      setIsInlineGeneratedTextNotInserted(true);
      setShowMenu(true);
      handleContinueWriting({
        shouldSetCommandValue: true,
        editorSelection: selection,
      });
    }
  }
}

export default function AiWriterMenuPlugin({
  anchorElem = document.body,
  linePlaceholderRef,
  showMenu,
  setShowMenu,
  lastSelection,
  setLastSelection,
  isRewriting,
  setIsRewriting,
  editableRef,
}: {
  anchorElem?: HTMLElement;
  linePlaceholderRef: React.RefObject<HTMLDivElement>;
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  lastSelection: RangeSelection | null;
  setLastSelection: React.Dispatch<React.SetStateAction<RangeSelection | null>>;
  isRewriting: boolean;
  setIsRewriting: React.Dispatch<React.SetStateAction<boolean>>;
  editableRef: React.RefObject<HTMLDivElement>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  return useAiWriterMenu(
    editor,
    anchorElem,
    linePlaceholderRef,
    showMenu,
    setShowMenu,
    lastSelection,
    setLastSelection,
    isRewriting,
    setIsRewriting,
    editableRef
  );
}
