import { ContentLayout } from "@/components/Layout";
import { useUser } from "@/features/auth/api/getUser";
import { useCommunityTemplates } from "@/features/templates/api/getCommunityTemplates";
import { useGlobalTemplates } from "@/features/templates/api/getGlobalTemplates";
import { useGetTemplatesForOrg } from "@/features/templates/api/getTemplatesForOrg";
import { useEffect, useMemo, useState } from "react";
import AiToolsGrid from "../components/AiToolsGrid";
import AiToolsHeader, { useAiToolsStore } from "../components/AiToolsHeader";

const getNestedValue = (obj, path) => {
  return path
    .split(".")
    .reduce((acc, part) => (acc ? acc[part] : undefined), obj);
};

const filterFunctionFactory = (filter) => {
  switch (filter.filterType) {
    case "tag":
      return (templateValue) => {
        return templateValue
          ?.toLowerCase()
          .split(",")
          .map((tag) => tag.trim())
          .includes(filter.value.toLowerCase());
      };
    case "text":
      return (templateValue) => {
        const title = templateValue?.[0]?.title || "";
        return (
          typeof title === "string" &&
          title.toLowerCase().includes(filter.value.toLowerCase())
        );
      };
    default:
      return () => false;
  }
};

const AiTools = () => {
  const { selectedType } = useAiToolsStore();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAuthorHash, setSelectedAuthorHash] = useState<string | null>(
    null
  );
  const [selectedAuthorName, setSelectedAuthorName] = useState<string | null>(
    ""
  );
  const { data: user, isLoading: isLoadingUser } = useUser();

  const { data: globalTemplatesData, isLoading: isLoadingGlobalTemplates } =
    useGlobalTemplates({
      type: "ai",
      selectedType,
      user,
    });

  const {
    data: communityTemplatesData,
    isLoading: isLoadingCommunityTemplates,
  } = useCommunityTemplates({
    user_hash: selectedAuthorHash!,
  });

  const { data: orgTemplatesData, isLoading: isLoadingOrgTemplates } =
    useGetTemplatesForOrg({
      org_id: user?.orgId!,
      type: "ai",
    });

  const isLoading =
    isLoadingUser ||
    isLoadingGlobalTemplates ||
    isLoadingCommunityTemplates ||
    isLoadingOrgTemplates;

  useEffect(() => {
    if (selectedType !== "community") {
      setSelectedAuthorHash(null);
      setSelectedAuthorName("");
    }
  }, [selectedType]);

  const templates = useMemo(() => {
    let combinedTemplates: Array<any> = [];
    switch (selectedType) {
      case "featured":
        combinedTemplates = [
          ...(globalTemplatesData || []),
          ...(orgTemplatesData || []),
        ];
        break;
      case "community":
        combinedTemplates = communityTemplatesData || [];
        console.log("communityTemplatesData", communityTemplatesData);
        break;
      case "created":
        combinedTemplates = orgTemplatesData || [];
        break;
      default:
        combinedTemplates = [];
    }
    combinedTemplates.sort((a, b) => {
      const titleA = getNestedValue(a, "text.0.title")?.toLowerCase() || "";
      const titleB = getNestedValue(b, "text.0.title")?.toLowerCase() || "";
      return titleA.localeCompare(titleB);
    });
    return combinedTemplates;
  }, [
    selectedType,
    globalTemplatesData,
    communityTemplatesData,
    orgTemplatesData,
  ]);

  const filteredTemplates = useMemo(() => {
    return templates?.filter(
      (template) =>
        selectedFilters.every((filter) => {
          const valueAtPath = getNestedValue(template, filter.parent);
          const filterFunction = filterFunctionFactory(filter);
          return filterFunction(valueAtPath);
        }) &&
        template.text[0]?.title
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
  }, [templates, selectedFilters, searchQuery]);

  return (
    <ContentLayout>
      <AiToolsHeader
        templates={templates}
        selectedType={selectedType}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <AiToolsGrid
        templates={filteredTemplates}
        selectedType={selectedType}
        setSelectedAuthorHash={setSelectedAuthorHash}
        setSelectedAuthorName={setSelectedAuthorName}
        selectedAuthorName={selectedAuthorName}
        selectedAuthorHash={selectedAuthorHash}
        isLoadingTemplates={isLoading}
      />
    </ContentLayout>
  );
};

export default AiTools;
