export const tiers = [
  {
    name: "Free",
    id: "tier-free-trial",
    href: "#",
    monthly: {
      stripeName: "free_trial",
      priceDisplay: "$0",
      price: 0,
    },
    yearly: {
      stripeName: "free_trial_yearly",
      priceDisplay: "$0",
      price: 0,
      price_per_month: "$0",
    },
    description: "For new users who want to try out the platform.",
    mostPopular: false,
    documents: "1",
    users: "1",
    order: 0,
    features: {
      docSharing: false,
      wordpress: false,
      googleDocs: false,
    },
    searchQueries: 1,
    display: false,
    apiUsage: "Up to 500 URLs/month",
  },
  {
    name: "Solo",
    id: "tier-solo",
    href: "#",
    monthly: {
      stripeName: "solo",
      priceDisplay: "$15",
      price: 14.99,
    },
    yearly: {
      stripeName: "solo_yearly",
      priceDisplay: "$152",
      price_per_month: "$12",
      price: 152,
    },
    description: "For new projects that require up to 1 article per week.",
    mostPopular: false,
    documents: "Unlimited",
    users: "1",
    order: 1,
    features: {
      docSharing: false,
      wordpress: true,
      googleDocs: false,
    },
    searchQueries: 10,
    display: true,
    apiUsage: "Up to 500 URLs/month",
  },
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    monthly: {
      stripeName: "basic_monthly_45",
      priceDisplay: "$45",
      price: 44.99,
      lifetimePlan: "basic_lifetime",
    },
    yearly: {
      stripeName: "basic_yearly_459",
      priceDisplay: "$459",
      price: 459,
      price_per_month: "$37",
      lifetimePlan: "basic_lifetime_yearly",
    },
    description: "For organizations with a content plan and SEO goals.",
    mostPopular: false,
    documents: "Unlimited",
    users: "1",
    order: 2,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: false,
    },
    searchQueries: 30,
    display: true,
    apiUsage: "Up to 500 URLs/month",
  },
  {
    name: "Team",
    id: "tier-team",
    href: "#",
    monthly: {
      stripeName: "team_monthly_115",
      priceDisplay: "$115",
      price: 114.99,
      lifetimePlan: "team_lifetime",
    },
    yearly: {
      stripeName: "team_yearly_1173",
      priceDisplay: "$1173",
      price: 1173,
      price_per_month: "$97",
      lifetimePlan: "team_lifetime_yearly",
    },
    description: "For teams that need more flexibility and collaboration.",
    mostPopular: false,
    documents: "Unlimited",
    users: "3 \n ($25 per extra)",
    order: 3,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: true,
    },
    searchQueries: "Unlimited",
    display: true,
    apiUsage: "Up to 500 URLs/month",
  },
];
