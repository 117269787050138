import frase_bot from "@/assets/frase_bot.png";
import { Button } from "@/components/Elements";
import { MagicCard } from "@/components/Elements/Card/MagicCard";
import { Skeleton } from "@/components/Elements/Skeleton";
import { useUser } from "@/features/auth/api/getUser";
import { useGlobalTemplates } from "@/features/templates/api/getGlobalTemplates";
import { cn } from "@/utils/style";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const OnboardingSelectTool = () => {
  const { data: user } = useUser();
  const { data: globalTemplatesData } = useGlobalTemplates({
    type: "ai",
    user,
  });
  const navigate = useNavigate();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleNext = () => {
    if (selectedTemplate) {
      navigate(`/app/ai-tools/${selectedTemplate.hash}`, {
        state: {
          onboardingTool: true,
        },
      });
    }
  };

  const handleBack = () => {
    navigate("/app/onboarding/workflow");
  };

  return (
    <div className="flex flex-col items-center justify-center h-full w-2/3 mx-auto absolute inset-0">
      <div className="flex flex-col items-center justify-center space-y-12">
        <div className="text-zinc-900 dark:text-white font-medium text-lg">
          <img
            src={frase_bot}
            alt="Frase Genius"
            className="rounded-xl w-12 h-12 border-2 start mb-4"
          />
          <p>Select an AI Tool to start generating content</p>
          <p className="text-sm text-zinc-500 dark:text-white mt-2">
            These are the most popular AI tools used by our community.
            Additional tools can be found in the AI Tools section.
          </p>
        </div>
        <div className="grid grid-cols-4 grid-rows-2 gap-2 w-full">
          {!globalTemplatesData ? (
            <>
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
              <Skeleton className="w-full h-36 min-h-36" />
            </>
          ) : (
            globalTemplatesData
              .filter((template) =>
                [
                  "Email Writer",
                  "Email Reply",
                  "Meta Description",
                  "Paragraph Rewriter",
                  "Facebook Post",
                  "Quora Answer",
                  "Blog Introduction",
                  "Blog Title Ideas",
                ].includes(template.text[0].title)
              )
              .map((template) => (
                <MagicCard
                  key={template.hash}
                  onClick={() => setSelectedTemplate(template)}
                  className={cn(
                    "flex flex-col w-48 h-36 min-h-36 cursor-pointer relative group p-2 rounded-md bg-white dark:bg-emerald-900/20",
                    template.hash === selectedTemplate?.hash
                      ? "ring-2 ring-inset ring-emerald-600/80 transition-all duration-300 bg-emerald-600/5 border-none"
                      : "transition-all duration-300 "
                  )}
                  gradientColor={"#059669"}
                  gradientOpacity={0.1}
                >
                  <div className="flex justify-between items-start pl-2 pr-1 pt-1">
                    <h3 className="text-xs font-medium flex-1 line-clamp-2">
                      {template.text[0].title || ""}
                    </h3>
                  </div>
                  <div className="flex flex-col px-2 py-1 justify-between w-full h-full">
                    <div className="flex flex-col space-y-2">
                      <p className="dark:text-zinc-400 text-zinc-600 text-2xs line-clamp-3">
                        {template.metadata.template_description}
                      </p>
                    </div>
                  </div>
                </MagicCard>
              ))
          )}
        </div>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex gap-x-2 justify-center w-full">
            <Button
              variant="outlineBlur"
              onClick={handleBack}
              className="w-full"
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              className="w-full"
              disabled={!selectedTemplate}
            >
              Next
            </Button>
          </div>
          <div className="text-zinc-400 dark:text-zinc-600 text-xs mx-auto">
            Step 6 out of 6
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSelectTool;
