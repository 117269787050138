export const sections = [
  {
    name: "Usage",
    features: [
      {
        name: "Users",
        tooltip: "Number of people in your organization.",
        tiers: {
          Solo: "1",
          Basic: "1",
          Team: "3 ($25/mo per extra)",
        },
      },
      {
        name: "Search Queries",
        tooltip:
          "Number of search queries you want to optimize content for. Typically, each article uses 1 query.",
        tiers: {
          Solo: "10",
          Basic: "30",
          Team: "Unlimited",
        },
      },
      {
        name: "API",
        tooltip:
          "Number of URLs you can process search results for using the API per month.",
        tiers: {
          Solo: "500 URLs/mo",
          Basic: "500 URLs/mo",
          Team: "500 URLs/mo",
        },
      },
    ],
  },
  {
    name: "SEO Workflow",
    features: [
      {
        name: "SERP Analysis",
        tooltip:
          "Analyze search results for your target queries. Research headings, topics, and more.",
        tiers: { Solo: true, Basic: true, Team: true },
      },
      {
        name: "Outline Builder",
        tooltip:
          "Curate headings from competitors and generate outline ideas with AI.",
        tiers: { Solo: true, Basic: true, Team: true },
      },
      {
        name: "Content Optimization",
        tooltip:
          "Use Frase's topic score as a blueprint of authoritative content.",
        tiers: { Solo: true, Basic: true, Team: true },
      },
    ],
  },
  {
    name: "AI Writing",
    features: [
      {
        name: "In-editor Writing Assistant",
        tooltip: "Expand and rewrite your content using AI.",
        tiers: {
          Solo: "20,000 AI words/mo",
          Basic: "Unlimited",
          Team: "Unlimited",
        },
      },
      {
        name: "AI Article Wizard (GPT-4)",
        tooltip: "Step-by-step workflow to generate your first draft with AI.",
        tiers: {
          Solo: "$35/mo Add-on",
          Basic: "$35/mo Add-on",
          Team: "$35/mo Add-on",
        },
      },
    ],
  },
  {
    name: "Plugins",
    features: [
      {
        name: "WordPress",
        tooltip: "Optimize your content inside WordPress.",
        tiers: { Solo: true, Basic: true, Team: true },
      },
      {
        name: "Google Docs",
        tooltip: "Optimize your content inside Google Docs.",
        tiers: { Solo: false, Basic: false, Team: true },
      },
    ],
  },
];
